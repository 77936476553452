<template>
  <div class="wrap">
    <div class="mb-2 tab-head mt-3">
      <a-input v-model:value="searchValue" class="search-input" :placeholder="$t('search')">
        <template #prefix>
          <search-outlined class="search-icon" />
        </template>
      </a-input>
<!--      <a-button @click="showAddUserModal = true" size="large" type="primary"><plus-outlined />{{ $t('add') }}</a-button>-->
      <a-button @click="exportData" size="large">{{ $t('export') }}</a-button>
    </div>

    <a-table
        :columns="columns"
        :data-source="filteredData"
        :pagination="{ ...pagination, locale: paginationLocale }"
        :customRow="customRow"
        :show-header="!isMobileView"
        :loading="loading"
    >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'company'">
          <span>
            <a-tag v-if="record.company"
                class="table-tag red-tag"
            >
              {{ record.company }}
            </a-tag>
          </span>
        </template>
        <template v-if="column.key === 'role'">
          <span>
            <a-tag
                class="table-tag red-tag"
            >
              {{ record.role }}
            </a-tag>
          </span>
        </template>
        <template v-if="column.key === 'access'">
          <span>
            <a-tag
                class="table-tag red-tag"
            >
              {{ record.access ? $t('granted') : $t('disabled')}}
            </a-tag>
          </span>
        </template>
        <template v-if="column.key === 'action'">
          <a-dropdown trigger="click">
            <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
              <more-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item
                    @click="showInfoModal = true"
                    key="1"
                >
                  {{ $t('more-info') }}
                </a-menu-item>
              </a-menu>
            </template>

          </a-dropdown>
        </template>
        <template v-if="column.key === 'all'">
          <a-card :title="$t('user')" class="table-card">
            <p class="user-card-row">
              <span>
                {{$t('full-name')}}
              </span>
              <span>{{record.name}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('nickname')}}
              </span>
              <span>{{record.login}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('company')}}
              </span>
              <span>{{record.company}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('email')}}
              </span>
              <span>{{record.email}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('number')}}
              </span>
              <span>{{record.number}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('role')}}
              </span>
              <span>{{record.role}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('access-app')}}
              </span>
              <span>
                <a-tag class="table-tag red-tag">
                  {{ record.access ? $t('granted') : $t('disabled')}}
                </a-tag>
              </span>
            </p>
          </a-card>
        </template>
      </template>
    </a-table>
  </div>

  <a-modal v-model:visible="showInfoModal" title="Info Modal" @ok="handleOkInfo">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOkInfo">{{$t('delete')}}</a-button>
      <a-button key="back" @click="handleCancelInfo">{{$t('cancel')}}</a-button>

    </template>
    <a-row class="data-container">
      Info
    </a-row>
  </a-modal>
</template>

<script>
import {
  MoreOutlined,
  SearchOutlined,
  // PlusOutlined,
} from "@ant-design/icons-vue";
import {notification} from "ant-design-vue";

export default {
  components: {
    MoreOutlined,
    SearchOutlined,
    // PlusOutlined,
  },
  data() {
    return {
      searchValue: '',
      showAddUserModal: false,
      showInfoModal: false,
      user: JSON.parse(localStorage.getItem('user')),
      dataSource: [],
      loading: true,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 2,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} z ${total} položek`,
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      paginationLocale: {
        items_per_page: '/ stránce',
        jump_to: 'Přejít na',
        jump_to_confirm: 'Potvrdit',
        page: '',
        prev_page: 'Předchozí stránka',
        next_page: 'Další stránka',
        prev_5: 'Předchozích 5 stránek',
        next_5: 'Dalších 5 stránek',
        prev_3: 'Předchozí 3 stránky',
        next_3: 'Další 3 stránky',
      },
    };
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isMobileView() {
      return this.$store.getters['isMobileView']
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: '',
            dataIndex: 'all',
            key: 'all'
          }
        ]
      }
      return [
        {
          title: this.$t('full-name'),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: this.$t('nickname'),
          dataIndex: 'login',
          key: 'login',
        },
        {
          title: this.$t('company'),
          dataIndex: 'company',
          key: 'company',
        },
        {
          title: this.$t('email'),
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: this.$t('number'),
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: this.$t('role'),
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: this.$t('access-app'),
          dataIndex: 'access',
          key: 'access',
        },
      ]
    }
  },
  async mounted() {
    if (this.$route.query.page) {
      this.pagination.current = +this.$route.query.page
    }
    if(this.$route.query.pageSize) {
      this.pagination.pageSize = +this.$route.query.pageSize;
    }
    await this.getUsersList()
  },
  methods: {
    async getUsersList() {
      try {
        const path = this.$route.path;
        const { current, pageSize } = this.pagination;
        if(!this.$route.query.pageContact) {
          await this.$router.push({ path, query: { page: current, pageSize: pageSize} });
        }

        const {data} = await this.$store.dispatch('UserModule/getDataBaseList', {
          page_size: this.pagination.pageSize,
          page: this.pagination.current
        })
        this.dataSource = data.results
        this.loading = false
        this.pagination.current = data.current
        this.pagination.pageSize = data.page_size
        this.pagination.total = data.count
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getUsersList();
    },
    handleActionClick(record) {
      console.log('Selected Option:', record.selected);
    },
    customRow(record) {
      return {
        onClick: () => {
          console.log(record.key)
          // const slug = record.key
          // this.$router.push({ name: "SupplierDetail", params: { slug } })
        },
      };
    },
    async exportData() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'export_user')) {
        try {
          const {data} = await this.$store.dispatch('UserModule/exportData', {
            users_uids: this.dataSource.map(s => s.uid)
          })
          window.open(data?.url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      } else if (this.isUserSuperadmin) {
        try {
          const {data} = await this.$store.dispatch('UserModule/exportData', {
            users_uids: this.dataSource.map(s => s.uid)
          })
          window.open(data?.url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    handleOkInfo() {
      this.showInfoModal = false
    },
    handleCancelInfo() {
      this.showInfoModal  = false
    },

  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/inputs.scss";
@import "~@/assets/scss/components/table-styles.scss";
.wrap {
  padding: 0 40px;
}
.tab-head {
  display: flex;
  align-items: center;
  gap: 10px;
}
:deep(.ant-table-row) {
  cursor: initial;
}
</style>