<template>
  <div class="home-wrap">
    <div class="page-head">
      <a-button @click="$router.push('/suppliers')" class="icon-button" type="text" icon="">
        <arrow-left-outlined
            class="trigger"
        />
      </a-button>

<!--      <div :style="{ backgroundImage: `url(${imageUrl})`, width: '32px', height: '32px' }"></div>-->
      <span v-if="!loading">{{details.name}}</span>
      <a-skeleton v-else active title :paragraph="{rows: 0}" style="max-width: 300px"></a-skeleton>
      <div class="top-btns">
        <a-button @click="exportData" size="large">{{ $t('export') }}</a-button>
        <a-button
            v-if="isUserSuperadmin || isUserAdmin"
            @click="deleteHandler"
            class="delete-btn"
            size="large"
            type="primary"
        >
          {{ $t('delete') }}
        </a-button>
      </div>

    </div>
    <div class="tabs-container">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="$t('reg-data')">
          <FirstTab :details="details" @refresh="refreshView"/>
        </a-tab-pane>
        <a-tab-pane key="2" :tab="$t('users-list')">
          <SecondTab :details="details" />
        </a-tab-pane>
        <a-tab-pane key="3" :tab="$t('price-sessions')"></a-tab-pane>
        <a-tab-pane key="4" :tab="$t('response-units')">
          <FourthTab />
        </a-tab-pane>
      </a-tabs>
    </div>
    <DeleteSModal
        :title="$t('modal-titles.delete-sup')"
        :company="details"
        :show-modal="showDeleteSupModal"
        @close="handleCloseDelete"
        @delete="handleOkDelete"
    ></DeleteSModal>
  </div>
</template>

<script>
import FirstTab from "@/components/SuppliersTabs/FirstTab";
import SecondTab from "@/components/SuppliersTabs/SecondTab";
import FourthTab from "@/components/SuppliersTabs/FourthTab";
import DeleteSModal from "@/components/Modals/DeleteSModal";
import {
  ArrowLeftOutlined,
  // FolderOutlined
} from '@ant-design/icons-vue';
import {notification} from "ant-design-vue";

export default {
  name: 'Home',
  components: {
    ArrowLeftOutlined,
    // FolderOutlined,
    FirstTab,
    SecondTab,
    FourthTab,
    DeleteSModal
  },
  data() {
    return {
      imageUrl: require('@/assets/icons/Avatar.svg'),
      activeKey: '1',
      showDeleteSupModal: false,
      user: JSON.parse(localStorage.getItem('user')),
      uid: '',
      details: {},
      loading: true,
    }
  },
  async mounted() {
    try {
      if (this.$route.params.slug) {
        this.uid = this.$route.params.slug
      }
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
      }

      const {data} = await this.$store.dispatch('Suppliers/getSupDetail', this.uid)

      this.details = data
      this.loading = false
    } catch (e) {
      notification.error({
        message: this.$t('notifications.error'),
        description: e.response.data.errors[0].detail,
      })
    }
  },
  watch: {
    activeKey(v) {
      const path = this.$route.path;

      if(v === '2') {
        this.$router.push({ path, query: {
            tab: v,
            pageUser: this.$route?.query?.pageUser,
            pageSizeUser: this.$route?.query?.pageSizeUser
          }});
      } else if (v === '3') {
        this.$router.push({ path, query: {
            tab: v
          } });
      } else if (v === '4') {
        this.$router.push({ path, query: {
            tab: v
          } });
      }
      else if (v === '1') {
        this.$router.push({ path, query: {
            tab: v,
            pageContact: this.$route?.query?.pageContact
          } })
      }
    }
  },
  computed: {
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isUserAdmin() {
      return this.$store.getters["UserModule/user"].role === "Admin";
    },
  },
  methods: {
    async refreshView() {

      const {data} = await this.$store.dispatch('Suppliers/getSupDetail', this.uid)
      this.details = data

    },
    deleteHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'delete_supplier')) {
        this.showDeleteSupModal = true
      } else if (this.isUserSuperadmin) {
        this.showDeleteSupModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async handleOkDelete() {
      try {
        await this.$store.dispatch('Suppliers/deleteSupplier', this.uid)
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.profile-updated'),
        })
        this.showDeleteSupModal = false
        await this.$router.push('/suppliers')
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handleCloseDelete() {
      this.showDeleteSupModal = false
    },
    async exportData() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'export_supplier')) {
        try {
          window.open(this.details?.export_url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      } else if (this.isUserSuperadmin) {
        try {
          window.open(this.details?.export_url, "_blank");
        } catch (e) {
          notification.error({
            message: this.$t('notifications.error'),
            description: e.response.data.errors[0].detail,
          })
        }
      }

      else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    }
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/pages/home.scss";
</style>
