<template>
  <div class="mb-2 tab-head mt-3">
    <a-input
      v-model:value="searchValue"
      class="search-input"
      :placeholder="$t('search')"
    >
      <template #prefix>
        <search-outlined class="search-icon" />
      </template>
    </a-input>
    <a-button @click="showAddUserHandler" size="large" type="primary"
      ><plus-outlined />{{ $t("add") }}</a-button
    >
  </div>

  <a-table
    :columns="columns"
    :data-source="filteredData"
    :pagination="{ ...pagination, locale: paginationLocale }"
    :loading="loading"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'action'">
        <a-dropdown trigger="click">
          <a-button
            shape="circle"
            class="more-btn"
            @click.stop="handleActionClick(record)"
          >
            <more-outlined />
          </a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                  @click="handleShowEditUser"
                  key="3"
              >
                <edit-outlined />
                <span>{{ $t('edit') }}</span>
              </a-menu-item>
              <a-menu-item @click="RFCHandler" key="1">
                <delete-outlined />
                <span>{{ $t("modal-titles.delete-f-company") }}</span>
              </a-menu-item>
              <a-menu-item @click="DFSHandler" key="2"
                ><delete-outlined />
                {{ $t("delete-f-system") }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
      <template v-if="column.key === 'all'">
        <a-card :title="$t('user')" class="table-card">
          <p class="user-card-row">
            <span>{{ $t("full-name") }}</span>
            <span>{{ record.name }}</span>
          </p>
          <p class="user-card-row">
            <span>{{ $t("nickname") }}</span>
            <span>{{ record.nick }}</span>
          </p>
          <p class="user-card-row">
            <span>
              {{ $t("email") }}
            </span>
            <span>{{ record.email }}</span>
          </p>
          <p class="user-card-row">
            <span>
              {{ $t("number") }}
            </span>
            <span>{{ record.number }}</span>
          </p>
          <p class="user-card-row">
            <span>
              {{ $t("actions") }}
            </span>
            <span>
              <a-dropdown trigger="click">
                <a-button
                  shape="circle"
                  class="more-btn"
                  @click.stop="handleActionClick(record)"
                >
                  <more-outlined />
                </a-button>
                <template #overlay>
                  <a-menu>
                    <a-menu-item
                        @click="handleShowEditUser"
                        key="3"
                    >
                      <edit-outlined />
                      <span>{{ $t('edit') }}</span>
                    </a-menu-item>
                    <a-menu-item @click="RFCHandler" key="1">
                      <delete-outlined />
                      <span>{{ $t("remove-from") }}</span>
                    </a-menu-item>
                    <a-menu-item @click="DFSHandler" key="2"
                      ><delete-outlined />
                      {{ $t("delete-f-system") }}
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </span>
          </p>
        </a-card>
      </template>
    </template>
  </a-table>
  <UserModal
    v-if="showAddUserModal"
    :show-modal="showAddUserModal"
    :title="$t('user')"
    :companyUsers="dataSource"
    @add="handleAddUser($event)"
    @close="handleCancelAdd"
  >
  </UserModal>
  <EmergencyModal2
      v-if="showAddUserEmergencyModal"
      :show-modal="showAddUserEmergencyModal"
      user-type="supplier"
      :parent-name="details?.name"
      @update="handleAdd($event)"
      @close="handleCancelAdd"
  ></EmergencyModal2>
  <EmergencyModal2
      v-if="showEditUserEmergencyModal"
      :show-modal="showEditUserEmergencyModal"
      user-type="supplier"
      :is-edit="true"
      :user-object="selectedUser"
      :parent-name="details?.name"
      @update="handleEdit($event)"
      @close="handleCancelEdit"
  ></EmergencyModal2>
  <DeleteFromSystem
    :show-modal="showDeleteFromSystemModal"
    @close="handleCancelDFS"
    @delete="handleOkDFS"
  >
  </DeleteFromSystem>
  <DeleteFromCompany
    :show-modal="showRemoveFromCompanyModal"
    @close="handleCancelRFC"
    @delete="handleOkRFC"
  >
  </DeleteFromCompany>
</template>

<script>
import {
  MoreOutlined,
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined, EditOutlined,
} from "@ant-design/icons-vue";
import UserModal from "@/components/Modals/UserModal";
import DeleteFromCompany from "@/components/Modals/DeleteFromCompany";
import DeleteFromSystem from "@/components/Modals/DeleteFromSystem";
import EmergencyModal2 from "@/components/Modals/EmergencyModal2";
import { notification } from "ant-design-vue";

export default {
  components: {
    EditOutlined,
    MoreOutlined,
    SearchOutlined,
    PlusOutlined,
    DeleteOutlined,
    UserModal,
    DeleteFromCompany,
    DeleteFromSystem,
    EmergencyModal2,
  },
  props: {
    details: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      searchValue: "",
      showAddUserModal: false,
      showAddContactModal: false,
      showDeleteFromSystemModal: false,
      showRemoveFromCompanyModal: false,
      showAddUserEmergencyModal: false,
      showEditUserEmergencyModal: false,
      user: JSON.parse(localStorage.getItem("user")),
      newContact: {
        name: "",
        mail: "",
        number: "",
        positions: [],
      },
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 2,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} z ${total} položek`,
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      paginationLocale: {
        items_per_page: "/ stránce",
        jump_to: "Přejít na",
        jump_to_confirm: "Potvrdit",
        page: "",
        prev_page: "Předchozí stránka",
        next_page: "Další stránka",
        prev_5: "Předchozích 5 stránek",
        next_5: "Dalších 5 stránek",
        prev_3: "Předchozí 3 stránky",
        next_3: "Další 3 stránky",
      },
      uid: "",
      selectedUser: "",
      useFullUserDialog: true,
      loading: true,
    };
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource;
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter((item) => {
        return Object.keys(item).some((key) => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters["UserModule/user"].role === "Superadmin";
    },
    isUserAdmin() {
      return this.$store.getters["UserModule/user"].role === "Admin";
    },
    isMobileView() {
      return this.$store.getters["isMobileView"];
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: "",
            dataIndex: "all",
            key: "all",
          },
        ];
      }
      return [
        {
          title: this.$t("full-name"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$t("nickname"),
          dataIndex: "nick",
          key: "nick",
        },
        {
          title: this.$t("email"),
          dataIndex: "email",
          key: "email",
        },
        {
          title: this.$t("number"),
          dataIndex: "number",
          key: "number",
        },
        {
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 150,
        },
      ];
    },
  },
  async mounted() {
    if (this.$route.params.slug) {
      this.uid = this.$route.params.slug;
    }
    if (this.$route.query.pageUser) {
      this.pagination.current = +this.$route.query.pageUser;
    }
    if (this.$route.query.pageSizeUser) {
      this.pagination.pageSize = +this.$route.query.pageSizeUser;
    }
    await this.getUsersList();
  },
  methods: {
    async refreshView() {
      if (this.$route.query.pageUser) {
        this.$route.query.pageUser = 1
        this.pagination.current = 1
      }
      if (this.$route.query.pageSizeUser) {
        this.pagination.pageSize = +this.$route.query.pageSizeUser;
      }
      await this.getUsersList()
    },
    showAddUserHandler() {
      if (
        this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "change_supplier"
        ) && this.isUserAdmin
      ) {
        this.showAddUserModal = true;
      } else if (this.isUserSuperadmin) {
        this.showAddUserModal = true;
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    DFSHandler() {
      if (
        this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "delete_user"
        ) && this.isUserAdmin
      ) {
        this.showDeleteFromSystemModal = true;
      } else if (this.isUserSuperadmin) {
        this.showDeleteFromSystemModal = true;
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    RFCHandler() {
      if (
        this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "change_supplier"
        ) && this.isUserAdmin
      ) {
        this.showRemoveFromCompanyModal = true;
      } else if (this.isUserSuperadmin) {
        this.showRemoveFromCompanyModal = true;
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    async getUsersList() {
      try {
        const path = this.$route.path;
        const { current, pageSize } = this.pagination;
        await this.$router.push({
          path,
          query: { tab: "2", pageUser: current, pageSizeUser: pageSize },
        });
        const { data } = await this.$store.dispatch("Suppliers/getSupUsers", {
          uid: this.uid,
          page_size: this.pagination.pageSize,
          page: this.pagination.current,
        });

        this.dataSource = data.results;
        this.loading = false
        this.pagination.current = data.current;
        this.pagination.pageSize = data.page_size;
        this.pagination.total = data.count;
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getUsersList();
    },
    handleActionClick(record) {
      this.selectedUser = record;
    },
    async handleShowEditUser() {
      if (this.$store.getters["UserModule/user"]?.user_permissions?.find(
          (p) => p.codename === "change_user"
      ) && this.isUserAdmin) {
        this.showEditUserEmergencyModal = true
      } else if (this.isUserSuperadmin) {
        this.showEditUserEmergencyModal = true
      } else {
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("no-permission"),
        });
      }
    },
    handleCancelEdit() {
      this.showEditUserEmergencyModal = false
    },
    async handleOkDFS() {
      try {
        await this.$store.dispatch("UserModule/deleteUser", this.selectedUser.uid);
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.user-deleted"),
        });

        this.refreshView()

        this.showDeleteFromSystemModal = false;
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    handleCancelDFS() {
      this.showDeleteFromSystemModal = false;
    },
    async handleOkRFC() {
      try {
        await this.$store.dispatch("Suppliers/unAssignUser", {
          uid: this.uid,
          users_uids: [this.selectedUser.uid],
        });
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.user-unassigned"),
        });

        this.refreshView()

        this.showRemoveFromCompanyModal = false;
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    handleCancelRFC() {
      this.showRemoveFromCompanyModal = false;
    },
    async handleEdit(user) {
      try {
        await this.$store.dispatch(
            "Emergency/updateUser",
            user
        );
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.user-updated"),
        });
        this.showAddUserEmergencyModal = false;
        this.showEditUserEmergencyModal = false;
        
        this.refreshView()

      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    async handleAddUser(user) {
      try {
        if (user === "create") {
          if (
            this.$store.getters["UserModule/user"]?.user_permissions?.find(
              (p) => p.codename === "add_user"
            ) && (this.isUserAdmin || this.isUserSuperadmin)
          ) {
            this.showAddUserModal = false;
            if (this.useFullUserDialog) {
              this.showAddUserEmergencyModal = true;
            } else {
              this.showAddContactModal = true;
            }
            return;
          } else if (this.isUserSuperadmin) {
            this.showAddUserModal = false;
            if (this.useFullUserDialog) {
              this.showAddUserEmergencyModal = true;
            } else {
              this.showAddContactModal = true;
            }
            return;
          } else {
            notification.error({
              message: this.$t("notifications.error"),
              description: this.$t("no-permission"),
            });
            return;
          }
        }

        await this.$store.dispatch("Suppliers/assignUser", {
          uid: this.uid,
          users_uids: [user],
        });
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.user-assigned"),
        });

        this.refreshView()

        this.showAddUserModal = false;
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    handleCancelAdd() {
      this.showAddUserModal = false;
      this.showAddUserEmergencyModal = false;
    },
    async handleAdd(user) {
      try {
        const response = await this.$store.dispatch(
          "Emergency/createUser",
          user
        );
        if (response?.data?.uid) {
          await notification.success({
            message: this.$t("notifications.success"),
            description: this.$t("notifications.sub-created"),
          });
          // await this.$store.dispatch("Suppliers/assignUser", {
          //   uid: this.uid,
          //   users_uids: [response.data.uid],
          // });
          this.showAddUserEmergencyModal = false;
          
          this.refreshView()

        } else {
          await notification.error({
            message: this.$t("notifications.error"),
            description: this.$t("notifications.sub-not-created"),
          });
        }
      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/inputs.scss";
@import "~@/assets/scss/components/table-styles.scss";
.tab-head {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
