import axios from '@/axios';

export default {
  namespaced: true,
  state: {
    // Module state goes here
  },
  mutations: {
    // Module mutations go here
  },
  actions: {
    async deleteDocument(ctx, payload) {
      return await axios.delete(`/suppliers/documents/delete/${payload}/`)
    },
    async createDocument(ctx, payload) {
      return await axios.post('/suppliers/documents/create/', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async getSupsList(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get('/suppliers/list/', {
        params: queryParams
      })
    },
    async getSupDetail(ctx, payload) {
      return await axios.get(`/suppliers/detail/${payload}/`)
    },
    async getSupContacts(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/suppliers/contacts/list/${payload.uid}/`, {
        params: queryParams
      })
    },
    async getSeparatedContactsList(ctx, payload) {
      return await axios.get(`/suppliers/positions/contacts/list/${payload}/`)
    },
    async getSupUsers(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/suppliers/users/list/${payload.uid}/`, {
        params: queryParams
      })
    },
    async createSupplier(ctx, payload) {
      return await axios.post('/suppliers/create/', payload)
    },
    async editSupplier(ctx, payload) {
      return await axios.patch(`/suppliers/update/${payload.uid}/`, payload.companyData)
    },
    async deleteSupplier(ctx, payload) {
      return await axios.delete(`/suppliers/delete/${payload}/`)
    },
    async createContact(ctx, payload) {
      return await axios.post('/suppliers/contact/create/', payload)
    },
    async updateContact(ctx, payload) {
      return await axios.patch(`/suppliers/contact/update/${payload.uid}/`, payload.data)
    },
    async deleteContact(ctx, payload) {
      return await axios.delete(`/suppliers/contact/delete/${payload}/`)
    },
    async getPositionsList() {
      return await axios.get('/subscribers/postions/list/')
    },
    async createNewUser(ctx, payload) {
      return await axios.post('/suppliers/user/create/', payload)
    },
    async assignUser(ctx, payload) {
      return await axios.patch(`/suppliers/users/assign/${payload.uid}/`, {
        users_uids: payload.users_uids
      })
    },
    async unAssignUser(ctx, payload) {
      return await axios.patch(`/suppliers/users/unassign/${payload.uid}/`, {
        users_uids: payload.users_uids
      })
    },
    async exportData(ctx, payload) {
      return await axios.post(`/suppliers/export/`, payload)
    },
    async getListOfUnits(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/suppliers/response/units/list/${payload.uid}/`, {
        params: queryParams
      })
    },
    async getUnitsList(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/suppliers/response/units/list/all/`, {
        params: queryParams
      })
    },
    async getListOfUnitsForModal() {
      return await axios.get('/suppliers/response/units/list/all/')
    },
    async assignUnit(ctx, payload) {
      return await axios.patch(`/suppliers/response/units/assign/${payload.uid}/`, {
        response_units_uids: payload.response_units_uids
      })
    },
    async unAssignUnit(ctx, payload) {
      return await axios.patch(`/suppliers/response/units/unassign/${payload.uid}/`, {
        response_units_uids: payload.response_units_uids
      })
    },
    async deleteUnit(ctx, payload) {
      return await axios.delete(`/suppliers/response/units/delete/${payload}/`)
    },
    async createUnit(ctx, payload) {
      return await axios.post(`/suppliers/response/units/create/`, payload)
    },
    async updateUnit(ctx, payload) {
      return await axios.patch(`/suppliers/response/units/update/${payload.uid}/`, payload)
    },
    async getSuppliersListForModal() {
      return await axios.get('/suppliers/list/all/')
    },
    async exportUnits(ctx, payload) {
      return await axios.post(`/suppliers/export/units/`, payload)
    },
    async exportDetail(ctx, payload) {
      return await axios.post(`/suppliers/export/detail/`, payload)
    }
  },
  getters: {
    // Module getters go here
  }
};
