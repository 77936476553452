<template>
  <a-modal v-model:visible="localShowModal" :title="$t('modal-titles.delete-contact')" @cancel="handleCancel" width="400px">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('delete')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <h2>{{contact?.name}}</h2>
    <h4>{{contact?.company}}</h4>
    <a-row class="data-container">
      <a-col :span="24">
        <a-row>
          <a-col :span="24">
            <div class="data">
              <span class="sub">{{ $t('email') }}</span>
              <span class="text">{{contact?.email}}</span>
            </div>
            <div class="data mt-1">
              <span class="sub">{{ $t('number') }}</span>
              <span class="text">{{contact?.number}}</span>
            </div>
            <div class="data mt-1">
              <span class="sub">{{ $t('position') }}</span>
              <span class="text">
                <a-tag
                    class="table-tag tag-break"
                    v-for="tag in contact?.positions"
                    :key="tag"

                    :class="[tag !== $t('o-matters') ? 'red-tag' : 'orange-tag']"
                >
              {{ tag }}
            </a-tag>
              </span>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    contact: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      localContact: Object.assign({}, this.contact),
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    contact(newVal) {
      this.localContact = Object.assign({}, newVal);
    },
  },
  methods: {
    handleOk() {
      this.$emit('delete')
    },
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/table-styles.scss";
.tag-break {
  white-space: break-spaces;
}
</style>