<template>
  <a-layout style="min-height: 100vh">
    <a-layout-header
        v-if="!isLargeScreen && user && $route.path !== '/auth'"
    >
      <div @click="$router.push('/')" class="logo ma-0" />
      <a-button type="text" @click="toggleDrawer" class="burger-btn">
        <MenuOutlined />
      </a-button>

    </a-layout-header>
    <a-layout-sider
        v-if="user && isLargeScreen && $route.path !== '/auth'"
        v-model:collapsed="collapsed" collapsible
    >
      <template #trigger>

        <a-button class="trigger-btn" :icon-only="true" @click.stop="collapsed = !collapsed">
          <DoubleRightOutlined v-if="collapsed" />
          <DoubleLeftOutlined v-else />
        </a-button>
      </template>
      <div @click="$router.push('/')" class="logo" />
      <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
        <a-menu-item @click="$router.push('/')" key="home">
          <home-outlined style="color: var(--red-7)"></home-outlined>
          <span>Homepage</span>
        </a-menu-item>
        <a-sub-menu key="sub1" >
          <template #title>
            <span>
              <team-outlined style="color: var(--red-7)" />
              <span>{{ $t('partners') }}</span>
            </span>
          </template>
          <a-menu-item v-if="user?.role === 'Admin' || user?.role === 'Superadmin'" @click="$router.push('/emergency-service')" key="3">
            <customer-service-outlined style="color: var(--red-7)"></customer-service-outlined>
            <span>{{ $t('emergency-service') }}</span>
          </a-menu-item>
          <a-menu-item v-if="hasPermission('view_subscriber') || isPCO" @click="$router.push('/subscribers')" key="4">
            <user-outlined style="color: var(--red-7)"></user-outlined>
            <span>{{ $t('subscribers') }}</span>
          </a-menu-item>
          <a-menu-item v-if="(hasPermission('view_supplier') || isSBS) && !isPCO" @click="$router.push('/suppliers')" key="5">
            <shopping-cart-outlined style="color: var(--red-7)"></shopping-cart-outlined>
            <span>{{ $t('suppliers') }}</span>
          </a-menu-item>
          <a-menu-item v-if="hasPermission('view_user')" @click="$router.push('/user-database')" key="6">
            <hdd-outlined style="color: var(--red-7)"></hdd-outlined>
            <span>{{ $t('user-database') }}</span>
          </a-menu-item>
          <a-menu-item v-if="(hasPermission('view_responseunit') || isPCO) && !isSBS" @click="$router.push('/response-units')" key="7">
            <folder-open-outlined style="color: var(--red-7)"></folder-open-outlined>
            <span>{{ $t('response-units') }}</span>
          </a-menu-item>
          <a-sub-menu v-if="false" key="sub2" >
            <template #title>
          <span>
            <file-text-outlined style="color: var(--red-7)" />
            <span>{{ $t('order-records') }}</span>
          </span>
            </template>
            <a-menu-item key="8">Team 1</a-menu-item>
            <a-menu-item key="9">Team 2</a-menu-item>
          </a-sub-menu>
          <a-menu-item v-if="false" key="10">
            <tag-outlined style="color: var(--red-7)"></tag-outlined>
            <span>{{ $t('actions') }}</span>
          </a-menu-item>
        </a-sub-menu>

      </a-menu>
      <div class="user-section">
        <a-dropdown v-model:visible="showDropdown" placement="top">
          <template #overlay>
            <a-menu @click="showDropdown = false">
              <a-menu-item @click="profileHandler" class="p-menu-item" key="profile">{{ $t('profile') }}</a-menu-item>
              <a-menu-item @click="logout" class="p-menu-item" key="logout">{{ $t('logout') }}</a-menu-item>
            </a-menu>
          </template>
          <a class="ant-dropdown-link" @click="showDropdown = !showDropdown">
            <div class="avatar-container">
              <a-avatar class="avatar" :src="user?.avatar || require('@/assets/icons/logo.svg')" alt="User Avatar" />
              <transition name="fade">
                <span v-if="!collapsed" class="username">{{ user?.name }}</span>
              </transition>
            </div>
            <i class="fa fa-caret-down"></i>
          </a>
        </a-dropdown>
      </div>
    </a-layout-sider>
    <a-layout>
      <a-layout-content style="background: #1F1F1F">
        <router-view />
      </a-layout-content>
    </a-layout>
    <a-drawer
        :visible="drawerVisible && !isLargeScreen && $route.path !== '/auth'"
        :closable="false"
        :width="300"
        placement="right"
        :getContainer="false"
        @close="drawerVisible = false"
    >
      <div class="drawer-head">
        <a-button type="text" @click="toggleDrawer" class="burger-btn">
          <CloseOutlined />
        </a-button>
      </div>
      <a-menu v-model:selectedKeys="selectedKeys" class="menu-nav" theme="dark" mode="inline">
        <a-menu-item @click="$router.push('/')" key="home">
          <home-outlined style="color: var(--red-7)"></home-outlined>
          <span>Homepage</span>
        </a-menu-item>
        <a-sub-menu key="sub1" >
          <template #title>
            <span>
              <team-outlined style="color: var(--red-7)" />
              <span>{{ $t('partners') }}</span>
            </span>
          </template>
          <a-menu-item v-if="hasPermission('view_user')" @click="$router.push('/emergency-service')" key="3">
            <customer-service-outlined style="color: var(--red-7)"></customer-service-outlined>
            <span>{{ $t('emergency-service') }}</span>
          </a-menu-item>
          <a-menu-item v-if="hasPermission('view_subscriber') || isPCO" @click="$router.push('/subscribers')" key="4">
            <user-outlined style="color: var(--red-7)"></user-outlined>
            <span>{{ $t('subscribers') }}</span>
          </a-menu-item>
          <a-menu-item v-if="(hasPermission('view_supplier') || isSBS) && !isPCO" @click="$router.push('/suppliers')" key="5">
            <shopping-cart-outlined style="color: var(--red-7)"></shopping-cart-outlined>
            <span>{{ $t('suppliers') }}</span>
          </a-menu-item>
          <a-menu-item v-if="hasPermission('view_user')" @click="$router.push('/user-database')" key="6">
            <hdd-outlined style="color: var(--red-7)"></hdd-outlined>
            <span>{{ $t('user-database') }}</span>
          </a-menu-item>
          <a-menu-item v-if="(hasPermission('view_responseunit') || isPCO) && !isSBS" @click="$router.push('/response-units')" key="7">
            <folder-open-outlined style="color: var(--red-7)"></folder-open-outlined>
            <span>{{ $t('response-units') }}</span>
          </a-menu-item>
          <a-sub-menu v-if="false" key="sub2" >
            <template #title>
          <span>
            <file-text-outlined style="color: var(--red-7)" />
            <span>{{ $t('order-records') }}</span>
          </span>
            </template>
            <a-menu-item key="8">Team 1</a-menu-item>
            <a-menu-item key="9">Team 2</a-menu-item>
          </a-sub-menu>
          <a-menu-item v-if="false" key="10">
            <tag-outlined style="color: var(--red-7)"></tag-outlined>
            <span>{{ $t('actions') }}</span>
          </a-menu-item>
        </a-sub-menu>
      </a-menu>
      <div class="user-section-mob">
        <a-dropdown v-model:visible="showDropdownMobile" placement="top">
          <template #overlay>
            <a-menu @click="showDropdown = false">
              <a-menu-item @click="profileHandler" class="p-menu-item" key="profile">{{ $t('profile') }}</a-menu-item>
              <a-menu-item @click="logout" class="p-menu-item" key="logout">{{ $t('logout') }}</a-menu-item>
            </a-menu>
          </template>
          <a class="ant-dropdown-link" @click="showDropdown = !showDropdown">
            <div class="avatar-container">
              <a-avatar class="avatar" :src="user?.avatar || require('@/assets/icons/logo.svg')" alt="User Avatar" />
              <transition name="fade">
                <span class="username">{{ user?.name }}</span>
              </transition>
            </div>
            <i class="fa fa-caret-down"></i>
          </a>
        </a-dropdown>
      </div>
    </a-drawer>
  </a-layout>


</template>

<script>
import {
  TagOutlined,
  TeamOutlined,
  FileTextOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  CustomerServiceOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  HddOutlined,
  FolderOpenOutlined,
  MenuOutlined,
  CloseOutlined,
  HomeOutlined
} from '@ant-design/icons-vue';
import {Layout, notification} from 'ant-design-vue'
import EventPass from "@/event-pass";
import {
  setValueToLocalStorage,
  getValueFromLocalStorage
} from './utils/utils';

export default {
  name: 'AppLayout',
  components: {
    'a-layout': Layout,
    'a-layout-content': Layout.Content,
    'a-layout-sider': Layout.Sider,
    TeamOutlined,
    FileTextOutlined,
    TagOutlined,
    DoubleRightOutlined,
    DoubleLeftOutlined,
    CustomerServiceOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    HddOutlined,
    FolderOpenOutlined,
    MenuOutlined,
    CloseOutlined,
    HomeOutlined
  },
  data() {
    return {
      user: null,
      collapsed: this.checkStorageValue(),
      imageUrls: require('@/assets/icons/Avatar.svg'),
      selectedKeys: [],
      activeKey: '1',
      showDropdown: false,
      isLargeScreen: false,
      drawerVisible: false,
    }
  },
  async mounted() {
    EventPass.$on('userLogged', this.fetchUser)
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    this.$store.commit('changeStatusOfView', !this.isLargeScreen)
    await this.fetchUser()

  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    showDropdownMobile() {
      return !!(this.showDropdown && !this.isLargeScreen)
    },
    userSectionWidth() {
      return this.collapsed ? '80px' : '200px'
    },
    hasPermission() {
      const userRole = this.$store.getters['UserModule/user'].role;
      const userPermissions = this.$store.getters['UserModule/user'].user_permissions;
      return (permission) => {
        if (userRole === 'Superadmin') {
          return true; // Superadmin has all permissions
        }

        return userPermissions?.some((p) => p.codename === permission);
      };
    },
    isSBS() {
      return this.$store.getters['UserModule/user'].role === 'SBS'
    },
    isPCO() {
      return this.$store.getters['UserModule/user'].role === 'PCO'
    }
  },
  watch: {
    isLargeScreen(v) {
      if(v) {
        this.$store.commit('changeStatusOfView', false)
        this.drawerVisible = false
      } else {
        this.$store.commit('changeStatusOfView', true)
      }
    },
    $route() {
      this.drawerVisible = false;
    },
    collapsed(v) {
      setValueToLocalStorage('kruh-aside-collapsed', v)
    },
  },
  methods: {
    checkStorageValue() {
      let collapsed = true;
      const storageCollapsed = getValueFromLocalStorage('kruh-aside-collapsed');
      if (storageCollapsed !== null) collapsed = !!(storageCollapsed === "true");

      return collapsed;
    },
    async fetchUser() {
      if(localStorage.getItem('accessToken')) {
        this.user = {}
      }
      this.selectedKeys = []
      try {
        if (localStorage.getItem('accessToken')) {
          const {data} = await this.$store.dispatch('UserModule/getUserDetail')
          this.user = data
          this.$store.commit('UserModule/commitUserData', data)
          await localStorage.setItem('role', JSON.stringify(data.role))
          await localStorage.setItem('permissions', JSON.stringify(data.user_permissions))
          await localStorage.setItem("supplier", JSON.stringify(data?.supplier));
          await localStorage.setItem("subscriber", JSON.stringify(data?.subscriber));
          await localStorage.setItem('last-object', JSON.stringify(data?.last_object))
          const routeKeyMapping = {
            '/emergency-service': '3',
            '/subscribers': '4',
            '/suppliers': '5',
            '/user-database': '6',
            '/response-units': '7',
            '/profile': 'profile',
            '/': 'home'
          };

          const currentPath = window.location.pathname;

          Object.entries(routeKeyMapping).forEach(([route, key]) => {
            if (currentPath === '/') {
              if (route === '/') {
                this.selectedKeys.push(key); // Push '/' key only for the home page
              }
            } else if (currentPath === route) {
              this.selectedKeys.push(key); // Push the key for exact matches (non-home pages)
            } else if (currentPath.startsWith(route + '/')) {
              this.selectedKeys.push(key); // Push the key for pages that start with the route followed by '/'
            }
          });
        }
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 1150;
    },
    profileHandler() {
      this.$router.push('/profile')
      this.selectedKeys = []
    },
    logout() {
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('permissions')
      localStorage.removeItem('role')
      localStorage.removeItem('supplier');
      localStorage.removeItem('subscriber');
      this.user = null
      this.$router.push('/auth')
    }
  }
}
</script>

<style lang="scss">

@import "~@/assets/scss/global.scss";
.ant-menu-item {
  padding-left: 12px !important;
}
.ant-menu-submenu-title {
  padding-left: 12px !important;
}
.user-section {
  color: #FFFFFF;
  position: fixed;
  bottom: 50px;
  display: flex;
  justify-content: center;
  width: v-bind(userSectionWidth);
  transition: all 0.2s;
  align-items: center;
  & .avatar {
    width: 48px;
    height: 48px;
  }
  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: v-bind(userSectionWidth);
    transition: all 0.2s;
    overflow: hidden;

  }
  .username {
    margin-left: 10px;
    color: #FFFFFF;
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.user-section-mob {
  color: #FFFFFF;
  position: fixed;
  bottom: 16px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  transition: all 0.2s;
  align-items: center;
  padding-left: 24px;
  border-top: 1px solid #434343;
  padding-top: 16px;
  & .avatar {
    width: 48px;
    height: 48px;
  }
  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    overflow: hidden;

  }
  .username {
    margin-left: 10px;
    color: #FFFFFF;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.fade-enter,
.fade-leave-to {
  transition: all;
  display: none;
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #434343;
  background: #141414 !important;
}

.burger-btn {
  border-color: var(--red-7) !important;
}
.ant-drawer-body {
  padding: 16px 0 !important;
  background: #141414 !important;
  border-left: 1px solid #1F1F1F;
}
.drawer-head {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 15px;
  border-bottom: 1px solid #434343;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: rgb(31, 31, 31) !important;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline {
  padding: 0 !important;
}
</style>
