<template>
  <a-modal v-model:visible="localShowModal" :title="title" @cancel="handleCancel">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('delete')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <h2>{{company?.name}}</h2>
    <a-row class="data-container">
      <a-col :xs="24" :md="12">
        <a-row>
          <a-col :span="24" class="title mb-1">{{ $t('basic-data') }}</a-col>
          <a-col :span="24">
            <div class="data">
              <span class="sub">{{ $t('ico') }}</span>
              <span class="text">{{company.ico}}</span>
            </div>
            <div class="data mt-1">
              <span class="sub">{{ $t('dic') }}</span>
              <span class="text">{{company.dic}}</span>
            </div>
          </a-col>

        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="second">
        <a-row>
          <a-col :span="24" class="title mb-1">{{ $t('residence') }}</a-col>
          <a-col :span="24">
            <div class="data">
              <span class="sub">{{ $t('street') }}</span>
              <span class="text">{{company?.street}}</span>
            </div>
            <div class="data mt-1">
              <span class="sub">{{ $t('district') }}</span>
              <span class="text">{{company?.district}}</span>
            </div>
            <div class="data mt-1">
              <span class="sub">{{ $t('zip') }}</span>
              <span class="text">{{company?.zip_code}}</span>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="mt-2">
        <a-row>
          <a-col :span="24">
            <div class="title">{{ $t('finance-data') }}</div>
            <div class="data mt-1">
              <span class="sub">{{ $t('account-number') }}</span>
              <span class="text">{{company?.account}}</span>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="mt-2">
        <a-row>
          <a-col :span="24">
            <div class="title">{{ $t('mailing-address') }}</div>
            <div class="data" v-if="!company?.mailing_address_input">
              <a-tag class="profile-tag">{{ $t('identical') }}</a-tag>
            </div>
            <div v-else>
              <div class="data mt-1">
                <span class="sub">{{ $t('street') }}</span>
                <span class="text">{{company?.mailing_address?.street}}</span>
              </div>
              <div class="data mt-1">
                <span class="sub">{{ $t('district') }}</span>
                <span class="text">{{company?.mailing_address?.district}}</span>
              </div>
              <div class="data mt-1">
                <span class="sub">{{ $t('zip') }}</span>
                <span class="text">{{company?.mailing_address?.zip_code}}</span>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      localCompany: Object.assign({}, this.company),
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    company(newVal) {
      this.localCompany = Object.assign({}, newVal);
    },
  },
  methods: {
    handleOk() {
      this.$emit('delete')
    },
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/table-styles.scss";
</style>