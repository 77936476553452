<template>
  <div v-if="isLoading" class="profile-wrap">
    <div class="section first-skeleton">
      <a-skeleton active title :paragraph="{rows: 0}"></a-skeleton>
      <a-skeleton-button></a-skeleton-button>
    </div>
    <div class="section mt-1">
      <a-skeleton-avatar active :size="64" shape="circle" />
    </div>
    <div class="section mt-1">
      <a-skeleton active :paragraph="{rows: 0}"></a-skeleton>
    </div>
    <div class="section mt-3">
      <a-skeleton active title :paragraph="{rows: 0}"></a-skeleton>
      <a-skeleton active :paragraph="{rows: 0}"></a-skeleton>
    </div>
    <div class="section mt-2">
      <a-skeleton active :paragraph="{rows: 1}"></a-skeleton>
    </div>
  </div>
  <div v-if="user && !isLoading" class="profile-wrap">
    <div class="section">
      <h3 class="section-title">{{ $t("basic-data") }}</h3>
      <a-button @click="save" type="primary" class="save-btn">{{
        $t("save")
      }}</a-button>
      <div class="mt-2 mb-1">
        <a-avatar :src="user.avatar || require('@/assets/icons/logo.svg')" :size="64" />
        <a-upload
          class="avatar-upload"
          :showUploadList="false"
          :beforeUpload="beforeAvatarUpload"
          :customRequest="uploadAvatar"
          accept="image/jpeg, image/png"
        >
          <span class="ml-1">{{ $t("change-avatar") }}</span>
        </a-upload>
      </div>
      <div class="inputs-container mt-1">
        <div class="inp-wrap">
          <label>{{ $t("full-name") }}</label>
          <a-input v-model:value="user.name" size="large"></a-input>
      </div>
    </div>
    <div class="section mt-3">
      <h3 class="section-title">{{ $t("account") }}</h3>
      <div class="inputs-container mt-1">
        <div class="inp-wrap">
          <label>{{ $t("nickname") }}</label>
          <a-input v-model:value="user.username" size="large"></a-input>
          <div v-if="isNickDirty">
            <div v-if="isNickRequired" class="error-message">
              {{ $t("field-required") }}
            </div>
          </div>
        </div>
        <div>
          <label>{{ $t("password") }}</label>
          <a-button class="reset-btn" @click="resetPassword" size="large">{{$t('reset-password')}}</a-button>
        </div>
      </div>
      <h3 class="section-title mt-2">{{ $t("contact-data") }}</h3>
      <div class="inputs-container mt-1">
        <div class="inp-wrap">
          <label>{{ $t("email") }}</label>
          <a-input v-model:value="user.email" size="large"></a-input>
          <div v-if="isUserEmailDirty">
            <div v-if="isUserEmailRequired" class="error-message">
              {{ $t("field-required") }}
            </div>
            <div v-else-if="isUserEmailValid" class="error-message">
              {{ $t("valid-email") }}
            </div>
          </div>
        </div>
        <div class="inp-wrap">
          <label>{{ $t("number") }}</label>
          <a-input
            v-model:value="user.number"
            @input=" !(/^[+]?[0-9]*$/i).test(user.number) ? user.number = user.number.replace(/[^+^0-9]+/ig, '').replace(/(?<!^)\+|\^/ig, '') : null"
            type="tel"
            size="large"
          ></a-input>
          <div v-if="isNumberDirty && isNumberValid && user?.number?.length > 0" class="error-message">
            {{ $t("phone-format") }}
          </div>
        </div>
      </div>
    </div>
    <div class="section mt-3" v-if="user.role === 'Admin' || user.role === 'Superadmin'">
      <h3 class="section-title">{{ $t("access") }}</h3>
      <a-row>
        <a-col>
          <label>{{ $t("company") }}</label>
          <a-tag v-if="user.company" class="profile-tag">{{
            user.company
          }}</a-tag>
        </a-col>
      </a-row>
      <a-row class="mt-2">
        <a-col :xs="12" :md="6">
          <label>{{ $t("role") }}</label>
          <a-tag v-if="user.role" class="profile-tag">{{ user.role }}</a-tag>
        </a-col>
        <a-col :xs="12" :md="6">
          <label>{{ $t("access-app") }}</label>
          <a-tag class="profile-tag">{{
            user.is_access_granted ? $t("granted") : $t("disabled")
          }}</a-tag>
        </a-col>
      </a-row>
      <a-row class="mt-2">
        <a-col :span="12">
          <label>{{ $t("permissions") }}</label>
          <template v-if="user?.user_permissions?.length">
            <a-tag
              v-for="o in user?.user_permissions"
              :key="o"
              class="profile-tag"
            >
              <span>{{ o.name }}</span>
            </a-tag>
          </template>
          <a-tag v-else-if="user.role === 'Superadmin'" class="profile-tag">
            {{ $t("superadmin-permissions") }}
          </a-tag>
          <a-tag v-else class="profile-tag">
            {{ $t("have-no-permissions") }}
          </a-tag>
        </a-col>
      </a-row>
    </div>
  </div>
  </div>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { useVuelidate } from "@vuelidate/core/dist/index.mjs";
import { notification } from "ant-design-vue";
import parsePhoneNumberFromString from 'libphonenumber-js';

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  components: {},
  data() {
    return {
      user: {},
      isFocused: false,
      formDataAvatar: null,
      isLoading: true,
    };
  },
  computed: {
    isUserNameOrEmailFilled() {
      return !!(this.user?.email?.length > 0 || this.user?.username?.length > 0)
    }
  },
  watch: {
    "user.password"(val) {
      if (val === "") {
        delete this.user.password;
      }
    },
  },
  async mounted() {
    try {
      const { data } = await this.$store.dispatch("UserModule/getUserDetail");
      this.user = data;
      this.user.number = this.user.phone;
      this.isLoading = false
    } catch (e) {
      notification.error({
        message: this.$t("notifications.error"),
        description: e.response.data.errors[0].detail,
      });
    }
  },
  methods: {
    async save() {
      try {
        this.v$.$touch();
        if (this.isUserEmailValid || this.isUserEmailRequired || this.isNickRequired) {
          return;
        }
        if(this.user?.number?.length > 0 && this.isNumberValid) {
          return
        }

        if(!this.user?.number?.length) {
          delete this.user?.number
          delete this.user?.phone
          this.user.phone = null // Validation inconsistency with EmergencyModal and EmergencyModal2
        } else {
          this.user.number = parsePhoneNumberFromString(this.user.number, 'CZ').nationalNumber 
          this.user.phone = this.user.number // Validation inconsistency with EmergencyModal and EmergencyModal2
        }

        await this.$store.dispatch("UserModule/updateUserData", this.user);
        if (this.formDataAvatar) {
          await this.$store.dispatch(
            "UserModule/updateAvatar",
            this.formDataAvatar
          );
        }

        // localStorage.setItem('user', JSON.stringify(this.user))
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.profile-updated"),
        });
        const { data } = await this.$store.dispatch("UserModule/getUserDetail");
        this.user = data;
        this.user.number = this.user.phone;
      } catch (e) {
        console.log(e);
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    },
    beforeAvatarUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        notification.error({
          message: this.$t("notifications.error"),
          description: "You can only upload JPG/PNG file!",
        });
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        notification.error({
          message: this.$t("notifications.error"),
          description: "Image must smaller than 2MB!",
        });
      }
      return isJpgOrPng && isLt2M;
    },
    async uploadAvatar({ file }) {
      const formData = new FormData();
      formData.append("avatar", file);
      this.user.avatar = URL.createObjectURL(file);
      this.formDataAvatar = formData;
    },
    handleClose(source, item) {
      if (source === "orders") {
        this.user.orders = this.user.orders.filter((i) => i !== item);
      }
      if (source === "actions") {
        this.user.actions = this.user.actions.filter((i) => i !== item);
      }
      if (source === "complaint") {
        this.user.complaint = this.user.complaint.filter((i) => i !== item);
      }
      if (source === "other") {
        this.user.other = this.user.other.filter((i) => i !== item);
      }
    },
    async resetPassword() {
      try {
        if(this.user.email.length > 0) {
          await this.$store.dispatch('UserModule/resetRequest', {
            email: this.user.email
          })
          await notification.success({
            message: this.$t("notifications.success"),
            description: this.$t("notifications.reset-request"),
          });
        } else {
          notification.error({
            message: this.$t("notifications.error"),
            description: this.$t('notifications.reset-error'),
          });
        }

      } catch (e) {
        notification.error({
          message: this.$t("notifications.error"),
          description: e.response.data.errors[0].detail,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/pages/profile.scss";
.avatar-upload {
  cursor: pointer;
  span:hover {
    color: var(--red-7);
  }
}
.reset-btn {
  border-radius: 4px
}
.first-skeleton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
