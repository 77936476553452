<template>
  <div>
    <a-table
        :columns="columns"
        :data-source="dataSource"
        :loading="loading"
        :pagination="false"
        :footer="false"
        :customRow="customRow"
        :show-header="!isMobileView"
    >
      <template #bodyCell="{ column, record }">
<!--        <template v-if="column.key === 'name'">-->
<!--          <div style="display: flex; align-items: center; gap: 8px">-->
<!--            <a-avatar :src="record.avatar" :size="22"></a-avatar>-->
<!--            <span>{{ record.name }}</span>-->
<!--          </div>-->
<!--        </template>-->
        <template v-if="column.key === 'position'">
          <span>
            <a-tag
                class="table-tag"
                v-for="tag in record.positions"
                :key="tag"

                :class="[tag !== $t('o-matters') ? 'red-tag' : 'orange-tag']"
            >
              {{ tag }}
            </a-tag>
          </span>
        </template>
        <template v-if="column.key === 'action'">
          <a-dropdown trigger="click">
            <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
              <more-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="$emit('edit', selectedContact)" key="2"><edit-outlined /> {{$t('edit')}} </a-menu-item>
                <a-menu-item @click="showDeleteHandler" key="1"><delete-outlined /> {{$t('delete')}} </a-menu-item>
              </a-menu>
            </template>

          </a-dropdown>
        </template>
        <template v-if="column.key === 'all'">
          <a-card :title="$t('user')" class="table-card">
            <p class="user-card-row">
              <span>
                {{$t('name')}}
              </span>
              <span>{{record.name}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('email')}}
              </span>
              <span>
                {{record.email}}
              </span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('number')}}
              </span>
              <span>{{record.number}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('position')}}
              </span>
              <span>
                <a-tag
                    class="table-tag"
                    v-for="tag in record.positions"
                    :key="tag"

                    :class="[tag !== $t('o-matters') ? 'red-tag' : 'orange-tag']"
                >
              {{ tag }}
            </a-tag>
              </span>
            </p>

            <p class="user-card-row">
              <span>
                {{$t('actions')}}
              </span>
              <span>
                <a-dropdown trigger="click">
                  <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
                    <more-outlined />
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="$emit('edit', selectedContact)" key="2"><edit-outlined /> {{$t('edit')}} </a-menu-item>
                      <a-menu-item @click="showDeleteHandler" key="1"><delete-outlined /> {{$t('delete')}} </a-menu-item>
                    </a-menu>
                  </template>

                </a-dropdown>
              </span>
            </p>
          </a-card>
        </template>
      </template>
    </a-table>
    <DeleteContactModal
        :contact="selectedContact"
        :show-modal="showDeleteContactModal"
        @close="handleCancel"
        @delete="handleDelete"
    ></DeleteContactModal>
  </div>
</template>

<script>
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons-vue";
import DeleteContactModal from "@/components/Modals/DeleteContactModal";
import {notification} from "ant-design-vue";

export default  {
  components: {
    MoreOutlined,
    DeleteOutlined,
    DeleteContactModal,
    EditOutlined
  },
  props: {
    dataSource:{
      type: Array,
      default: () => [],
    },
    paginationObj: {
      type: Object,
      default: () => {}
    },
    suppliers: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      showDeleteContactModal: false,
      selectedContact: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: this.dataSource.length,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      paginationLocale: {
        items_per_page: '/ stránce',
        jump_to: 'Přejít na',
        jump_to_confirm: 'Potvrdit',
        page: '',
        prev_page: 'Předchozí stránka',
        next_page: 'Další stránka',
        prev_5: 'Předchozích 5 stránek',
        next_5: 'Dalších 5 stránek',
        prev_3: 'Předchozí 3 stránky',
        next_3: 'Další 3 stránky',
      },
    }
  },
  computed: {
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isMobileView() {
      return this.$store.getters['isMobileView']
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: '',
            dataIndex: 'all',
            key: 'all'
          }
        ]
      }
      return [
        {
          title: this.$t('full-name'),
          dataIndex: 'name',
          key: 'name',
          sorter: (a, b) => a.name.localeCompare(b.name),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: this.$t('email'),
          dataIndex: 'email',
          key: 'email',
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortDirections: ['ascend', 'descend'],
          width: 230
        },
        {
          title: this.$t('number'),
          dataIndex: 'number',
          key: 'number',
          sorter: (a, b) => a.number.localeCompare(b.number),
          sortDirections: ['ascend', 'descend'],
          width: 150
        },
        {
          title: this.$t('position'),
          dataIndex: 'position',
          key: 'position',
          sortDirections: ['ascend', 'descend'],
          width: 250
        },
        {
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        },
      ]
    }
  },
  watch: {
    paginationObj(v) {
      this.pagination.current = v.current
      this.pagination.total = v.count
    }
  },
  methods: {
    showDeleteHandler() {
      if (!this.suppliers
          && this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'delete_contact_subscribers')) {
        this.showDeleteContactModal = true
      } else if (this.suppliers
          && this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'delete_contact_suppliers')) {
        this.showDeleteContactModal = true
      }
      else if (this.isUserSuperadmin) {
        this.showDeleteContactModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    rowSelection() {
      return {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        }
      }
    },
    handleActionClick(record) {
      this.selectedContact = record
    },
    customRow(record) {
      return {
        onClick: () => {
          this.$emit('contactSelect', record)
        },
      };
    },
    async handleDelete() {
      try {
        if (this.suppliers) {
          await this.$store.dispatch('Suppliers/deleteContact', this.selectedContact.uid)
        } else {
          await this.$store.dispatch('Subscribers/deleteContact', this.selectedContact.uid)
        }

        this.showDeleteContactModal = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.contact-deleted'),
        })

        this.$emit('refresh')

      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
      this.showDeleteContactModal = false
    },
    handleCancel() {
      this.showDeleteContactModal = false
    },
    handlePaginationChange(current) {
      this.$emit('pagination', current)
    },
  }
}
</script>



<style lang="scss" scoped>
@import "~@/assets/scss/components/table-styles.scss";
</style>
