import {useVuelidate} from '@vuelidate/core';
import {email, required} from '@vuelidate/validators';
import { parsePhoneNumber } from './../utils/utils';

export default {
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  computed: {
    isEmailDirty() {
      return this.v$.email.$dirty;
    },
    isEmailRequired() {
      return this.v$.email.required.$invalid;
    },
    isEmailValid() {
      return this.v$.email.email.$invalid;
    },
    isUserEmailDirty() {
      return this.v$.user.email.$dirty;
    },
    isUserEmailRequired() {
      return this.v$.user.email.required.$invalid;
    },
    isUserEmailValid() {
      return this.v$.user.email.email.$invalid;
    },
    isFNameDirty() {
      return this.v$.user.name.$dirty;
    },
    isFNameRequired() {
      return this.v$.user.name.required.$invalid;
    },
    isSNameDirty() {
      return this.v$.user.surname.$dirty;
    },
    isSNameRequired() {
      return this.v$.user.surname.required.$invalid;
    },
    isNameDirty() {
      return this.v$.user.name.$dirty;
    },
    isNameRequired() {
      return this.v$.user.name.required.$invalid;
    },
    isNumberDirty() {
      return this.v$.user.number.$dirty;
    },
    isNumberRequired() {
      return this.v$.user.number.required.$invalid;
    },
    isNumberValid() {
      return this.v$.user.number.customPhoneValidator.$invalid;
    },
    isNickDirty() {
      return this.v$.user.username.$dirty;
    },
    isNickRequired() {
      return this.v$.user.username.required.$invalid;
    },
    isUserPasswordDirty() {
      return this.v$.user.password.$dirty;
    },
    isUserPasswordRequired() {
      return this.v$.user.password.required.$invalid;
    },
    isUserPasswordValid() {
      return this.v$.user.password.customPasswordValidator.$invalid
    },
    isRolesDirty() {
      return this.v$.user.role.$dirty;
    },
    isRolesRequired() {
      return this.v$.user.role.required.$invalid;
    },
    isCodeValueDirty() {
      return this.v$.codeValue.$dirty;
    },
    isCodeValueRequired() {
      return this.v$.codeValue.required.$invalid;
    },
    isPasswordDirty() {
      return this.v$.password.$dirty;
    },
    isPasswordRequired() {
      return this.v$.password.required.$invalid;
    },
    isRepeatedPasswordDirty() {
      return this.v$.repeatedPassword.$dirty;
    },
    isRepeatedPasswordRequired() {
      return this.v$.repeatedPassword.required.$invalid;
    },
    isRepeatedPasswordSameAs() {
      return this.v$.repeatedPassword.sameAsPassword.$invalid;
    },
    isPasswordValid() {
      return this.v$.password.customPasswordValidator.$invalid
    },
    isCompanyNameDirty() {
      return this.v$.localCompany.name.$dirty
    },
    isCompanyNameRequired() {
      return this.v$.localCompany.name.required.$invalid
    },
    isCompanyIcoDirty() {
      return this.v$.localCompany.ico.$dirty
    },
    isCompanyIcoRequired() {
      return this.v$.localCompany.ico.required.$invalid
    },
    isCompanyDicDirty() {
      return this.v$.localCompany.dic.$dirty
    },
    isCompanyDicRequired() {
      return this.v$.localCompany.dic.required.$invalid
    },
    isCompanyStreetDirty() {
      return this.v$.localCompany.street.$dirty
    },
    isCompanyStreetRequired() {
      return this.v$.localCompany.street.required.$invalid
    },
    isCompanyDistrictDirty() {
      return this.v$.localCompany.district.$dirty
    },
    isCompanyDistrictRequired() {
      return this.v$.localCompany.district.required.$invalid
    },
    isCompanyZipDirty() {
      return this.v$.localCompany.zip_code.$dirty
    },
    isCompanyZipRequired() {
      return this.v$.localCompany.zip_code.required.$invalid
    },
    isCompanyAccountDirty() {
      return this.v$.localCompany.account.$dirty
    },
    isCompanyAccountRequired() {
      return this.v$.localCompany.account.required.$invalid
    },
    isCompanyMailingZipDirty() {
      return this.v$.localCompany.mailing_address.zip_code.$dirty
    },
    isCompanyMailingZipRequired() {
      return this.v$.localCompany.mailing_address.zip_code.required.$invalid
    },
    isCompanyMailingStreetDirty() {
      return this.v$.localCompany.mailing_address.street.$dirty
    },
    isCompanyMailingStreetRequired() {
      return this.v$.localCompany.mailing_address.street.required.$invalid
    },
    isCompanyMailingDistrictDirty() {
      return this.v$.localCompany.mailing_address.district.$dirty
    },
    isCompanyMailingDistrictRequired() {
      return this.v$.localCompany.mailing_address.district.required.$invalid
    },
    isContactNameDirty() {
      return this.v$.localContact.name.$dirty
    },
    isContactNameRequired() {
      return this.v$.localContact.name.required.$invalid
    },
    isContactUserNameDirty() {
      return this.v$.localContact.username.$dirty
    },
    isContactUserNameRequired() {
      return this.v$.localContact.username.required.$invalid
    },
    isContactNumberDirty() {
      return this.v$.localContact.number.$dirty
    },
    isContactNumberRequired() {
      return this.v$.localContact.number.required.$invalid
    },
    isContactNumberValid() {
      return this.v$.localContact.number.customPhoneValidator.$invalid
    },
    isContactMailDirty() {
      return this.v$.localContact.email.$dirty
    },
    isContactMailRequired() {
      return this.v$.localContact.email.required.$invalid
    },
    isContactMailValid() {
      return this.v$.localContact.email.email.$invalid;
    },
    isContactPositionsRequired() {
      return this.v$.localContact.positions.required.$invalid
    },
    isContactPositionsDirty() {
      return this.v$.localContact.positions.$dirty
    },
    isContactIdRequired() {
      return this.v$.localContact.id.required.$invalid
    },
    isContactIdDirty() {
      return this.v$.localContact.id.$dirty
    },
    isContactSimRequired() {
      return this.v$.localContact.sim.required.$invalid
    },
    isContactSimDirty() {
      return this.v$.localContact.sim.$dirty
    },
    isContactRnRequired() {
      return this.v$.localContact.rn.required.$invalid
    },
    isContactRnDirty() {
      return this.v$.localContact.rn.$dirty
    },
    isUnitRnRequired() {
      return this.v$.localUnit.rn.required.$invalid
    },
    isUnitRnDirty() {
      return this.v$.localUnit.rn.$dirty
    },
    isUnitSimRequired() {
      return this.v$.localUnit.sim.required.$invalid
    },
    isUnitSimDirty() {
      return this.v$.localUnit.sim.$dirty
    },
    isUnitSimValid() {
      return this.v$.localUnit.sim.customPhoneValidator.$invalid
    },
    isUnitIdRequired() {
      return this.v$.localUnit.unit_id.required.$invalid
    },
    isUnitIdDirty() {
      return this.v$.localUnit.unit_id.$dirty
    },
    isUnitSuppliersRequired() {
      return this.v$.localUnit.supplier.required.$invalid
    },
    isUnitSuppliersDirty() {
      return this.v$.localUnit.supplier.$dirty
    },
    isUnitLoginDirty() {
      return this.v$.localUnit.name.$dirty
    },
    isUnitLoginRequired() {
      return this.v$.localUnit.name.required.$invalid
    },
    isUnitNumberDirty() {
      return this.v$.localUnit.number.$dirty
    },
    isUnitNumberRequired() {
      return this.v$.localUnit.number.required.$invalid
    },
    isUnitNumberValid() {
      return this.v$.localUnit.number.customPhoneValidator.$invalid
    },
    isUnitAddressDirty() {
      return this.v$.localUnit.address.$dirty
    },
    isUnitAddressRequired() {
      return this.v$.localUnit.address.required.$invalid
    },
    isUnitGpsDirty() {
      return this.v$.localUnit.gps.$dirty
    },
    isUnitGpsRequired() {
      return this.v$.localUnit.gps.required.$invalid
    },
    isUnitGpsValid() {
      return this.v$.localUnit.gps.customGpsValidator.$invalid
    },
    isUnitPasswordDirty() {
      return this.v$.localUnit.password.$dirty
    },
    isUnitPasswordRequired() {
      return this.v$.localUnit.password.required.$invalid
    }
  },
  validations() {
    return {
      email: { required, email },
      codeValue: { required },
      firstName: { required },
      secondName: { required },
      number: { required },
      nickname: { required },

      password: {
        required,
        customPasswordValidator: (value) => {
          const hasCapitalLetter = /[A-Z]/.test(value);
          const hasNumber = /\d/.test(value);
          const hasSymbol = /[!@#$%^&*+]/.test(value);
          const passLength = value.length >= 8
          return hasCapitalLetter && hasNumber && passLength && hasSymbol;
        },
      },
      user: {
        email: { required, email },
        codeValue: { required },
        name: { required },
        surname: { required },
        number: {
          required,
          customPhoneValidator(value) {
            const phoneNumber = parsePhoneNumber(value);
            return !!(phoneNumber) && phoneNumber.isValid();
          },
        },
        username: { required },
        password: {
          required,
          customPasswordValidator: (value) => {
            const hasCapitalLetter = /[A-Z]/.test(value);
            const hasNumber = /\d/.test(value);
            const hasSymbol = /[!@#$%^&*+]/.test(value);
            const passLength = value.length >= 8
            return hasCapitalLetter && hasNumber && passLength && hasSymbol;
          },
        },
        role: {required}
      },
      repeatedPassword: {
        required,
        sameAsPassword: (value) => value === this.password,
      },
      localCompany: {
        ico: { required },
        dic: { required },
        name: { required },
        street: { required },
        zip_code: { required },
        district: { required },
        account: { required },
        mailing_address: {
          street: { required },
          zip_code: { required },
          district: { required },
        },
      },
      localContact: {
        name: { required },
        email: { required, email },
        number: {
          required,
          customPhoneValidator(value) {
            const phoneNumber = parsePhoneNumber(value);
            return !!(phoneNumber) && phoneNumber.isValid();
          },
        },
        username: {required},
        positions: { required },
        rn: { required },
        sim: { required },
        id: { required }
      },
      localUnit: {
        name: { required },
        number: {
          required,
          customPhoneValidator(value) {
            const phoneNumber = parsePhoneNumber(value);
            return !!(phoneNumber) && phoneNumber.isValid();
          },
        },
        supplier: { required },
        rn: { required },
        sim: {
          required,
          customPhoneValidator(value) {
            const phoneNumber = parsePhoneNumber(value);
            return !!(phoneNumber) && phoneNumber.isValid();
          },
        },
        unit_id: { required },
        gps: {
          required,
          customGpsValidator: (value) => {
            const regex = /^-?([1-8]?\d(?:\.\d{1,6})?|90(?:\.0{1,6})?),\s*-?((1[0-7]\d|0?\d{1,2})(?:\.\d{1,6})?|180(?:\.0{1,6})?)$/;
            if (!regex.test(value)) {
              return false;
            }
            return true;
          }
        },
        address: { required },
        password: { required }
      }
    };
  },
};
