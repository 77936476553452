<template>
  <a-modal
      v-model:visible="localShowModal"
      @cancel="handleCancel"
      width="700px"
      :closable="false"
      :maskClosable="false"
  >
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{
          $t("modal-actions.update")
        }}</a-button>
      <a-button key="back" @click="handleCancel">{{ $t("cancel") }}</a-button>
    </template>
    <h2 class="px-1">{{ $t("user") }}</h2>
    <a-row class="data-container">
      <a-col :xs="24" :md="12">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t("full-name") }}</label>
                <a-input v-model:value="user.name" size="large"></a-input>
                <div v-if="isNameDirty">
                  <div v-if="isNameRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("nickname") }}</label>
                <a-input v-model:value="user.username" size="large"></a-input>
                <div v-if="isNickDirty">
                  <div v-if="isNickRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("role") }}</label>
                <a-input v-model:value="user.role" disabled size="large"></a-input>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :md="12" class="second">
        <a-row>
          <a-col :span="24" class="px-1">
            <div class="data">
              <div class="inp-wrap">
                <label>{{ $t("number") }}</label>
                <a-input
                    v-model:value="user.number"
                    @input=" !(/^[+]?[0-9]*$/i).test(user.number) ? user.number = user.number.replace(/[^+^0-9]+/ig, '').replace(/(?<!^)\+|\^/ig, '') : null"
                    type="tel"
                    size="large"
                ></a-input>
                <div v-if="isNumberDirty" class="error-message">
                  <div v-if="isNumberRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                  <div v-else-if="isNumberValid && user?.number?.length > 0" class="error-message">
                    {{ $t("phone-format") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("email") }}</label>
                <a-input
                    v-model:value="user.email"
                    aria-autocomplete="none"
                    autocomplete="off"
                    size="large"
                ></a-input>
                <div v-if="isUserEmailDirty">
                  <div v-if="isUserEmailRequired" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                  <div v-else-if="isUserEmailValid" class="error-message">
                    {{ $t("valid-email") }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isEdit || isUserSuperadminOrAdmin" class="data mt-1">
              <div class="inp-wrap">
                <label>{{ $t("password") }}</label>
                <a-input-password
                    v-model:value="user.password"
                    :placeholder="$t(`${isEdit ? 'edit' : 'set'}-a-password`)"
                    aria-autocomplete="none"
                    autocomplete="new-password"
                    size="large"
                    :style="{
                      borderColor: isPasswordFocused ? '#e84749' : '#434343',
                      'box-shadow': 'none',
                    }"
                    @focus="isPasswordFocused = true"
                    @blur="isPasswordFocused = false"
                ></a-input-password>
                <div v-if="isUserPasswordDirty">
                  <div v-if="isUserPasswordRequired && !isEdit" class="error-message">
                    {{ $t("field-required") }}
                  </div>
                  <div v-else-if="isUserPasswordValid && user?.password?.length" class="error-message">
                    {{ $t("valid-password") }}
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="24" class="px-1">
        <div v-if="user.role !== 'Superadmin'" class="data mt-1">
          <div class="inp-wrap">
            <div class="tree-header">
              <label>{{ $t("permissions") }}</label>
            </div>
            <a-table
                :columns="columns"
                :data-source="dataSource"
                :pagination="false"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'name'">
                  {{ $t(record.name) }}
                </template>
                <template
                    v-if="column.key === 'view' && record.view !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                        v-model:checked="record.view"
                        @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                    v-if="column.key === 'all' && record.all !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                        v-model:checked="record.all"
                        @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
                <template
                    v-if="column.key === 'change' && record.change !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                        v-model:checked="record.change"
                        @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
<!--                <template-->
<!--                    v-if="column.key === 'add' && record.add !== undefined"-->
<!--                >-->
<!--                  <div class="checkbox-wrap">-->
<!--                    <a-checkbox-->
<!--                        v-model:checked="record.add"-->
<!--                        @change="onCheckboxChange(column, record)"-->
<!--                    ></a-checkbox>-->
<!--                  </div>-->
<!--                </template>-->
<!--                <template-->
<!--                    v-if="column.key === 'delete' && record.delete !== undefined"-->
<!--                >-->
<!--                  <div class="checkbox-wrap">-->
<!--                    <a-checkbox-->
<!--                        v-model:checked="record.delete"-->
<!--                        @change="onCheckboxChange(column, record)"-->
<!--                    ></a-checkbox>-->
<!--                  </div>-->
<!--                </template>-->
                <template
                    v-if="column.key === 'export' && record.export !== undefined"
                >
                  <div class="checkbox-wrap">
                    <a-checkbox
                        v-model:checked="record.export"
                        @change="onCheckboxChange(column, record)"
                    ></a-checkbox>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import ValidationMixin from "@/mixins/ValidationMixin";
import { useVuelidate } from "@vuelidate/core/dist/index.mjs";
import parsePhoneNumberFromString from 'libphonenumber-js';

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    userObject: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    userType: {
      type: String,
      default: 'subscriber'
    },
    parentName: {
      type: String,
      default: ''
    },
    parentUid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      user: Object.assign({}, this.userObject),
      matrixData: [],
      matchedPermissions: [],
      currentPermissions: [],
      selectAll: false,
      dataSource: [
        {
          name: this.parentName,
          view: false,
          all: false,
          change: false,
          export: false,
        },
      ],
      columns: [
        { title: this.$t("partners"), dataIndex: "name", key: "name" },
        {
          title: this.$t("allow-all"),
          dataIndex: "all",
          key: "all",
          type: "checkbox",
        },
        {
          title: this.$t("section-access"),
          dataIndex: "view",
          key: "view",
          type: "checkbox",
        },
        {
          title: this.$t("edit-perm"),
          dataIndex: "change",
          key: "change",
          type: "checkbox",
        },
        {
          title: this.$t("export-perm"),
          dataIndex: "export",
          key: "export",
          type: "checkbox",
        },
      ],
      isPasswordFocused: false,
    };
  },
  computed: {
    isUserSuperadminOrAdmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
          || this.$store.getters['UserModule/user'].role === 'Admin'
    },
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
    userObject(newVal) {
      this.user = Object.assign({}, newVal);
    },
  },
  async mounted() {
    if (this.userObject) {
      this.dataSource = this.userObject?.user_objects_permissions;
      this.matchedPermissions = this.userObject?.user_permissions;
    }
    if (this.userType === 'subscriber') {
      this.user.role = "PCO"
    } else {
      this.user.role = "SBS"
    }
  },
  methods: {
    onCheckboxChange(column, record) {

      if (["export", "change"].includes(column.key) 
        && !record["view"] 
        && record[column.key]) {
        record["view"] = true
      } else if (column.key === "view" 
        && !record["view"]) {
        record["export"] = false;
        record["change"] = false;
      }

      if (column.key === "all") {
        // Update all checkboxes in the current row with the value of the "All" checkbox
        for (const key in record) {
          if (key !== "key" && key !== "name" && key !== "model") {
            record[key] = record[column.key];
          }
        }
      } else {
        // If any checkbox other than "all" is unchecked, uncheck the "all" checkbox
        if (!record[column.key]) {
          record.all = false;
        } else {
          // Check if all other checkboxes are checked; if so, check the "all" checkbox
          let allChecked = true;
          for (const key in record) {
            if (key !== "key" && key !== "name" && key !== "all" && key !== "model" && key !== "uid") {
              if (!record[key]) {
                allChecked = false;
                break;
              }
            }
          }
          record.all = allChecked;
        }
      }
      console.log(this.dataSource)
    },
    handleOk() {
      this.v$.$touch();

      if (this.isUserEmailRequired || this.isUserEmailValid || this.isNickRequired || this.isNameRequired || this.isNumberRequired) {
        return;
      }

      if ((!this.isEdit || (this.isEdit && this.user?.password && this.user?.password?.length > 0)) &&
          (this.isUserPasswordRequired || this.isUserPasswordValid)) {
        return;
      }
      if(this.user?.number?.length > 0 && this.isNumberValid) {
        return
      }

      this.user.user_objects_permissions_input = this.dataSource.map(o => {
        return {
          object_model: this.userType === 'subscriber' ? 'subscriber' : 'supplier',
          object_uid: this.$route.params.slug,
          ...o
        }
      })
      if(!this.user?.password?.length) {
        delete this.user?.password
      }
      if(!this.user?.number?.length) {
        delete this.user?.number
        delete this.user?.phone // From api we get "number", but save "phone"
        this.user.phone = null
      } else {
        this.user.number = parsePhoneNumberFromString(this.user.number, 'CZ').nationalNumber
        this.user.phone = this.user.number // From api we get "number", but save "phone"
      }
      this.$emit("update", this.user);
      this.v$.$reset();
    },
    handleCancel() {
      this.user = {};
      this.selectAll = false;
      this.v$.$reset();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/components/first-tab.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  overflow: hidden !important;

  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }
}
.tree-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.checkbox-wrap {
  display: flex;
  justify-content: center;
}
:deep(.ant-table) {
  @media (max-width: 1150px) {
    overflow-x: scroll !important;
  }
}
</style>
