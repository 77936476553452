<template>
  <div class="home-wrap">
    <h1 class="head">Homepage</h1>
    <a-row>
      <a-col class="px-1" :span="12">
        <div class="home-col">
          <p class="col-title">Zakázky</p>
          <div class="col-content">

          </div>
        </div>

      </a-col>
      <a-col class="px-1"  :span="12">
        <div class="home-col">
          <p class="col-title">Notifikace</p>
          <div class="col-content">

          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    // const role = JSON.parse(localStorage.getItem('role'))
    // const subscriber = JSON.parse(localStorage.getItem('subscriber'))
    // const supplier = JSON.parse(localStorage.getItem('supplier'))
    // const permissions = JSON.parse(localStorage.getItem('permissions'))
    // const lastObject = JSON.parse(localStorage.getItem('last-object'))
    // if(localStorage.getItem('accessToken')) {
    //   if(role === 'Superadmin' || role === 'Admin') {
    //     this.$router.push('/emergency-service')
    //   } else if (role === 'User' && supplier && permissions.some(p => p.codename === 'view_supplier') && lastObject === 'supplier' ){
    //     this.$router.push(`/suppliers/${supplier}`)
    //   } else if (role === 'User' && subscriber && permissions.some(p => p.codename === 'view_subscriber') && lastObject === 'subscriber') {
    //     this.$router.push(`/subscribers/${subscriber}`)
    //   } else {
    //     this.$router.push('/profile')
    //   }
    // }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
@import "~@/assets/scss/pages/home.scss";
.home-wrap {
  //background: #141414 !important;
  height: 100vh;
  height: 100%;
  @media(max-width: 1149px) {
    height: calc(100vh - 64px);
  }
}
.head {
  text-align: left;
  padding-left: 1rem;
}
.home-col {
  text-align: left;
  background: #1F1F1F;
  padding: 40px 24px 40px 24px;
  .col-content {
    background: #2B2B2B;
    height: 396px;
    @media (max-width: 1149px) {
      height: 150px;
    }
  }
  .col-title {
    font-size: 20px;
    line-height: 28px;
  }
}
</style>
