<template>
  <div class="wrap">
    <div class="mb-2 records-head">
      <div class="inp-wrap">
        <div class="filter-head">
          <label>{{ $t('town') }}</label>
          <a-checkbox v-model:checked="selectAll" @change="toggleSelectAll">{{$t('all')}}</a-checkbox>
        </div>

        <a-select
            readonly
            v-model:value="town"
            style="width: 100%"
            class="select-custom-style"
            :placeholder="$t('select')"
            :options="options"
        ></a-select>
      </div>
      <div class="inp-wrap">
        <div class="filter-head">
          <label>{{ $t('district') }}</label>
          <a-checkbox v-model:checked="selectAll" @change="toggleSelectAll">{{$t('all')}}</a-checkbox>
        </div>
        <a-select
            readonly
            v-model:value="town"
            style="width: 100%"
            class="select-custom-style"
            :placeholder="$t('select')"
            :options="options"
        ></a-select>
      </div>
      <div class="inp-wrap">
        <div class="filter-head">
          <label>{{ $t('house_number') }}</label>
          <a-checkbox v-model:checked="selectAll" @change="toggleSelectAll">{{$t('all')}}</a-checkbox>
        </div>
        <a-select
            readonly
            v-model:value="town"
            style="width: 100%"
            class="select-custom-style"
            :placeholder="$t('select')"
            :options="options"
        ></a-select>
      </div>
      <div class="inp-wrap">
        <div class="filter-head">
          <label>{{ $t('resp-unit-id') }}</label>
          <a-checkbox v-model:checked="selectAll" @change="toggleSelectAll">{{$t('all')}}</a-checkbox>
        </div>
        <a-select
            readonly
            v-model:value="town"
            style="width: 100%"
            class="select-custom-style"
            :placeholder="$t('select')"
            :options="options"
        ></a-select>
      </div>
      <div class="btns-wrap">
        <a-button @click="showAddUnitHandler" class="head-btn" size="large" type="primary"><plus-outlined />{{ $t('add') }}</a-button>
        <a-button @click="exportData" class="head-btn" size="large">{{ $t('export') }}</a-button>
      </div>
    </div>
    <a-table
        :columns="columns"
        :data-source="filteredData"
        :loading="loading"
        :pagination="{
          ...pagination,
          showTotal: (total) => `${$t('total')} ${total} ${$t('items')}`,
          showSizeChanger: false,
          locale: paginationLocale
        }"
        :customRow="customRow"
        :show-header="!isMobileView"
    >
      <template #bodyCell="{ column, record }">
        <!--        <template v-if="column.key === 'name'">-->
        <!--          <div style="display: flex; align-items: center; gap: 8px">-->
        <!--            <a-avatar :src="record.avatar" :size="22"></a-avatar>-->
        <!--            <span>{{ record.name }}</span>-->
        <!--          </div>-->
        <!--        </template>-->
        <template v-if="column.key === 'order_response_units'">
          <span v-for="tag in record.order_response_units"
                :key="tag"
                class="tags-wrap"
          >
            <a-tag
                class="table-tag red-tag"
            >
              {{ tag.response_unit }}
            </a-tag>
            <a-tag class="table-tag orange-tag">
              {{ tag.arrival_time }}
            </a-tag>
          </span>
        </template>
        <template v-if="column.key === 'action'">
          <a-dropdown trigger="click">
            <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
              <more-outlined />
            </a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item @click="$emit('edit', selectedContact)" key="2"><edit-outlined /> {{$t('edit')}} </a-menu-item>
                <a-menu-item @click="showDeleteHandler" key="1"><delete-outlined /> {{$t('delete')}} </a-menu-item>
              </a-menu>
            </template>

          </a-dropdown>
        </template>
        <template v-if="column.key === 'all'">
          <a-card :title="$t('user')" class="table-card">
            <p class="user-card-row">
              <span>
                {{$t('town')}}
              </span>
              <span>{{record.town}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('district')}}
              </span>
              <span>
                {{record.street}}
              </span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('house-number')}}
              </span>
              <span>{{record.house_number}}</span>
            </p>
            <p class="user-card-row">
              <span>
                {{$t('id_and_time')}}
              </span>
              <span>
                <span v-for="tag in record.order_response_units"
                      :key="tag"
                      class="tags-wrap"
                >
                  <a-tag
                      class="table-tag red-tag"
                  >
                    {{ tag.response_unit }}
                  </a-tag>
                  <a-tag class="table-tag orange-tag">
                    {{ tag.arrival_time }}
                  </a-tag>
                </span>
              </span>

            </p>

            <p class="user-card-row">
              <span>
                {{$t('actions')}}
              </span>
              <span>
                <a-dropdown trigger="click">
                  <a-button shape="circle" class="more-btn" @click.stop="handleActionClick(record)">
                    <more-outlined />
                  </a-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="$emit('edit', selectedContact)" key="2"><edit-outlined /> {{$t('edit')}} </a-menu-item>
                      <a-menu-item @click="showDeleteHandler" key="1"><delete-outlined /> {{$t('delete')}} </a-menu-item>
                    </a-menu>
                  </template>

                </a-dropdown>
              </span>
            </p>
          </a-card>
        </template>
      </template>
    </a-table>
    <MapResponseUnit
        v-if="showAddUnitModal"
        :show-modal="showAddUnitModal"
        :unit="unit"
        @update="handleAddUnit($event)"
        @close="handleCancelAdd">
    </MapResponseUnit>
  </div>
</template>

<script>
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined, PlusOutlined
} from "@ant-design/icons-vue";
import {notification} from "ant-design-vue";
import MapResponseUnit from "@/components/Modals/MapResponseUnit.vue";

export default  {
  components: {
    PlusOutlined,
    MoreOutlined,
    DeleteOutlined,
    EditOutlined,
    MapResponseUnit
  },
  data() {
    return {
      selectAll: false,
      town: '',
      options: [],
      loading: false,
      unit: {
        unit_id: "",
        number: "",
        sim: "",
        name: "",
        supplier: "",
        gps: "",
        address: "",
        rn: ""
      },
      showAddUnitModal: false,
      dataSource: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        onChange: this.handlePaginationChange,
        onShowSizeChange: this.handlePaginationChange,
      },
      paginationLocale: {
        items_per_page: '/ stránce',
        jump_to: 'Přejít na',
        jump_to_confirm: 'Potvrdit',
        page: '',
        prev_page: 'Předchozí stránka',
        next_page: 'Další stránka',
        prev_5: 'Předchozích 5 stránek',
        next_5: 'Dalších 5 stránek',
        prev_3: 'Předchozí 3 stránky',
        next_3: 'Další 3 stránky',
      },
    }
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
    isMobileView() {
      return this.$store.getters['isMobileView']
    },
    columns() {
      if (this.isMobileView) {
        return [
          {
            title: '',
            dataIndex: 'all',
            key: 'all'
          }
        ]
      }
      return [
        {
          title: this.$t('town'),
          dataIndex: 'town',
          key: 'town',
          sorter: (a, b) => a.name.localeCompare(b.name),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: this.$t('district'),
          dataIndex: 'street',
          key: 'street',
          sorter: (a, b) => a.email.localeCompare(b.email),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: this.$t('house_number'),
          dataIndex: 'house_number',
          key: 'house_number',
          sorter: (a, b) => a.number.localeCompare(b.number),
          sortDirections: ['ascend', 'descend'],
        },
        {
          title: this.$t('id_and_time'),
          dataIndex: 'order_response_units',
          key: 'order_response_units',
          sorter: (a, b) => a.position.localeCompare(b.position),
          sortDirections: ['ascend', 'descend'],
        },
        {
          dataIndex: 'action',
          key: 'action',
          scopedSlots: { customRender: 'action' },
          width: 150
        },
      ]
    }
  },
  watch: {
    paginationObj(v) {
      this.pagination.current = v.current
      this.pagination.total = v.count
    }
  },
  async mounted() {
    if (this.$route.query.page) {
      this.pagination.current = +this.$route.query.page
    }
    if(this.$route.query.pageSize) {
      this.pagination.pageSize = +this.$route.query.pageSize;
    }
    await this.getOrdersList()
  },
  methods: {
    async getOrdersList() {
      try {
        const path = this.$route.path;
        const { current, pageSize } = this.pagination;
        await this.$router.push({ path, query: { page: current, pageSize: pageSize} });
        const { data } = await this.$store.dispatch('Map/getOrdersList', {
          page_size: this.pagination.pageSize,
          page: this.pagination.current
        })
        this.dataSource = data.results
        this.pagination.current = data.current
        this.pagination.pageSize = data.page_size
        this.pagination.total = data.count
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    rowSelection() {
      return {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(selectedRows);
        }
      }
    },
    handleActionClick(record) {
      this.selectedContact = record
    },
    customRow(record) {
      return {
        onClick: () => {
          this.$emit('contactSelect', record)
        },
      };
    },
    showAddUnitHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'add_responseunit')) {
        this.showAddUnitModal = true
      } else if (this.isUserSuperadmin) {
        this.showAddUnitModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async handleAddUnit(unit) {
      try {
        await this.$store.dispatch('Suppliers/createUnit', unit)

        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.unit-created'),
        })
        this.showAddUnitModal = false
        setTimeout(() => {
          window.location.reload()
        }, 700)
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelAdd() {
      this.showAddUnitModal = false
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getOrdersList();
    },
  }
}
</script>



<style lang="scss" scoped>
@import "~@/assets/scss/components/table-styles.scss";
@import "~@/assets/scss/components/auth-components.scss";
@import "~@/assets/scss/components/first-tab.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  overflow: hidden !important;

  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }

}
.wrap {
  padding: 3rem 40px 0;
  .records-head {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
}

.tags-wrap {
  display: inline-block;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
}
.filter-head {
  display: flex;
  justify-content: space-between;
}

</style>
