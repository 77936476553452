<template>
  <a-row class="wrap">
    <a-col :xs="24" :md="8" :lg="6" class="first-col">
      <div class="head">
        <div v-if="!loading" class="left">
          <h2 class="title">{{details?.name}}</h2>
          <a-button @click="updateSupHandler" class="edit-btn"><edit-outlined /> {{ $t('edit') }}</a-button>
        </div>
        <div v-else>
          <a-skeleton style="width: 300px" active title :paragraph="{rows: 0}"></a-skeleton>
          <a-skeleton-button></a-skeleton-button>
        </div>
<!--        <div>-->
<!--          <a-avatar :size="64" :src="require('@/assets/imgs/Avatar-2.svg')"></a-avatar>-->
<!--        </div>-->
      </div>
      <div v-if="!loading" class="data-container">
        <p class="title">{{ $t('basic-data') }}</p>
        <p class="data">
          <span class="sub">{{ $t('ico') }}</span>
          <span class="text">{{details?.ico}}</span>
          <a-button @click="copyToClipboard(details?.ico)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
        <p class="data">
          <span class="sub">{{ $t('dic') }}</span>
          <span class="text">{{details?.dic}}</span>
          <a-button @click="copyToClipboard(details?.dic)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
      </div>
      <div v-else>
        <a-skeleton active :paragraph="{rows: 2}"></a-skeleton>
      </div>
      <div v-if="!loading" class="data-container">
        <p class="title">{{ $t('finance-data') }}</p>
        <p class="data">
          <span class="sub">{{ $t('account-number') }}</span>
          <span class="text">{{details?.account}}</span>
          <a-button @click="copyToClipboard(details?.account)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
      </div>
      <div v-else>
        <a-skeleton active :paragraph="{rows: 2}"></a-skeleton>
      </div>
      <div v-if="!loading" class="data-container">
        <p class="title">{{ $t('residence') }}</p>
        <p class="data">
          <span class="sub">{{ $t('street') }}</span>
          <span class="text">{{details?.street}}</span>
          <a-button @click="copyToClipboard(details?.street)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
        <p class="data">
          <span class="sub">{{ $t('district') }}</span>
          <span class="text">{{details?.district}}</span>
          <a-button @click="copyToClipboard(details?.district)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
        <p class="data">
          <span class="sub">{{ $t('zip') }}</span>
          <span class="text">{{details?.zip_code}}</span>
          <a-button @click="copyToClipboard(details?.zip_code)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
      </div>
      <div v-else>
        <a-skeleton active :paragraph="{rows: 3}"></a-skeleton>
      </div>
      <div v-if="details?.mailing_address?.street" class="data-container">
        <p class="title">{{ $t('mailing-address') }}</p>
        <p class="data">
          <span class="sub">{{ $t('street') }}</span>
          <span class="text">{{details?.mailing_address?.street}}</span>
          <a-button @click="copyToClipboard(details?.mailing_address?.street)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
        <p class="data">
          <span class="sub">{{ $t('district') }}</span>
          <span class="text">{{details?.mailing_address?.district}}</span>
          <a-button @click="copyToClipboard(details?.mailing_address?.district)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
        <p class="data">
          <span class="sub">{{ $t('zip') }}</span>
          <span class="text">{{details?.mailing_address?.zip_code}}</span>
          <a-button @click="copyToClipboard(details?.mailing_address?.zip_code)" link ghost shape="circle" class="copy-btn">
            <copy-outlined />
          </a-button>
        </p>
      </div>
    </a-col>
    <a-col class="second-col" :xs="24" :md="16" :lg="18">
      <a-row>
        <a-col :span="24">
          <div class="second-col-head">
            <h3 class="tab-section-title">{{$t('contact-data')}}</h3>
          </div>
          <div v-if="!loading">
            <template
                v-for="pos in dataSource"
                :key=pos.uid
            >
              <div class="contact-head mt-1">
                <h5 class="contact-title">{{pos?.name}}</h5>
                <a-button @click="showAddContactHandler(pos?.name)" class="grey-btn"><plus-outlined />{{ $t('add') }}</a-button>
              </div>
              <TableComponent
                  v-if="pos?.contacts?.length"
                  :suppliers="true"
                  :loading="loading"
                  :data-source="pos?.contacts"
                  class="table-comp"
                  @edit="showContactInfoHandler($event)"
                  @refresh="getSupContacts"
              />
            </template>
          </div>
          <div v-else>
            <a-skeleton active></a-skeleton>
            <a-skeleton active></a-skeleton>
            <a-skeleton active></a-skeleton>
          </div>

        </a-col>
        <a-col v-if="!loading" :span="24" class="mt-2 docs-col" >
          <DocumentUploader
            :uid="uid"
            :docs="company?.docs"
            :userType="'Suppliers'"
            :userTypeFormData="'supplier'"
            @refresh="$emit('refresh')"
          />
        </a-col>
        <a-col v-else :span="24" class="mt-2 docs-col">
          <a-skeleton active></a-skeleton>
        </a-col>
      </a-row>
    </a-col>

  </a-row>
  <UpdateCompany
      :company="company"
      :show-modal="showEditModal"
      @close="handleCancelEditContact"
      @update="handleEditContact($event)"
  ></UpdateCompany>
  <ContactModal
      v-if="showAddContactModal"
      :contact="newContact"
      :show-modal="showAddContactModal"
      @close="handleCancelAddContact"
      @update="handleAddContact($event)">
  </ContactModal>
  <ContactModal
      v-if="showContactInfo"
      :contact="editContact"
      :is-edit="true"
      :show-modal="showContactInfo"
      @close="handleCloseContactInfo"
      @update="handleOkContactInfo($event)">
  </ContactModal>
</template>

<script>
import {
  EditOutlined,
  CopyOutlined,
  PlusOutlined,

} from '@ant-design/icons-vue';
import UpdateCompany from "@/components/Modals/Company";
import TableComponent from "@/components/TableComponent";
import ContactModal from "@/components/Modals/ContactModal";
import DocumentUploader from "@/components/common/DocumentUploader";
import {notification} from "ant-design-vue";
export default {
  name: 'FirstTab',
  props: {
    details: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    EditOutlined,
    CopyOutlined,
    PlusOutlined,
    TableComponent,
    UpdateCompany,
    ContactModal,
    DocumentUploader
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      showEditModal: false,
      imageUrl: require('@/assets/icons/Avatar.svg'),
      searchValue: '',
      loading: true,
      newContact: {
        name: '',
        mail: '',
        number: '',
        positions: [],
      },
      editContact: {},
      showAddContactModal: false,
      showContactInfo: false,
      company: {
        mailing_address_input: true,
        ico: '',
        dic: '',
        name: '',
        street: '',
        zip: '',
        district: '',
        account: '',
        docs: [],
        mailing_address: {
          street: '',
          zip: '',
          district: '',
        }
      },
      dataSource: [],
      uid: '',
      paginationObj: {}
    }
  },
  computed: {
    filteredData() {
      if (!this.searchValue) {
        return this.dataSource
      }
      const filterTextLower = this.searchValue.toLowerCase();
      return this.dataSource.filter(item => {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(filterTextLower);
        });
      });
    },
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    }
  },
  async mounted() {
    if (this.$route.params.slug) {
      this.uid = this.$route.params.slug
    }
    // if(this.$route.query.pageContact) {
    //   this.paginationObj.current = +this.$route.query.pageContact
    // }
    this.company = this.details

    await this.getSupContacts()

  },
  watch: {
    details(v) {
      this.company = v
    }
  },
  methods: {
    showAddContactHandler(posName) {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'add_contact_suppliers')) {
        this.newContact.positions = [posName]
        this.showAddContactModal = true
      } else if (this.isUserSuperadmin) {
        this.newContact.positions = [posName]
        this.showAddContactModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    updateSupHandler() {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'change_supplier')) {
        this.showEditModal = true
      } else if (this.isUserSuperadmin) {
        this.showEditModal = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async getSupContacts() {
      try {
        const {data} =  await this.$store.dispatch('Suppliers/getSeparatedContactsList', this.uid)
        this.dataSource = data.positions
        this.loading = false
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    // handleContactsPagination(page) {
    //   this.paginationObj.current = page
    //   this.getSupContacts()
    // },
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value)
      await notification.success({
        message: this.$t('value-copied'),
        description: value,
      });
    },
    handleActionClick() {
      console.log('clcqdw')
    },
    async handleEditContact(companyData) {
      try {
        await this.$store.dispatch('Suppliers/editSupplier', {
          uid: this.uid,
          companyData
        })
        this.showEditModal = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.sup-updated'),
        })

        this.$emit('refresh')

      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handleCancelEditContact() {
      this.showEditModal = false
    },
    async handleAddContact(contact) {
      try {
        await this.$store.dispatch('Suppliers/createContact', {
          supplier: this.uid,
          ...contact
        })
        this.showAddContactModal = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.contact-created'),
        })

        await this.getSupContacts()

      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    handleCancelAddContact() {
      this.showAddContactModal = false
    },
    showContactInfoHandler(data) {
      if (this.$store.getters['UserModule/user']?.user_permissions?.find(p => p.codename === 'change_contact_suppliers')) {
        this.editContact = data
        this.showContactInfo = true
      } else if (this.isUserSuperadmin) {
        this.editContact = data
        this.showContactInfo = true
      } else {
        notification.error({
          message: this.$t('notifications.error'),
          description: this.$t('no-permission'),
        })
      }
    },
    async handleOkContactInfo(contact) {
      try {
        await this.$store.dispatch('Suppliers/updateContact', {
          uid: this.editContact.uid,
          data: contact
        })
        this.showContactInfo = false
        await notification.success({
          message: this.$t('notifications.success'),
          description: this.$t('notifications.contact-updated'),
        })
        
        
        await this.getSupContacts()

      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }

    },
    handleCloseContactInfo() {
      this.showContactInfo = false
      this.editContact = {}
    },
  }


}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/first-tab.scss";
</style>