<template>
  <div>
    <h1 class="auth-title">{{ $t('registration') }}</h1>
    <div class="mt-2">
      <label>{{ $t('email') }}</label>
      <a-input v-model:value="email" size="large"></a-input>
      <div v-if="isEmailDirty">
        <div v-if="isEmailRequired" class="error-message">Email field is required.</div>
        <div v-else-if="isEmailValid" class="error-message">Email field must be a valid email.</div>
      </div>
    </div>
    <div v-if="codeNotVerified" class="mt-2">
      <label>{{ $t('code-mail') }}</label>
<!--      <a-input-group class="input-group">-->
        <a-input v-model:value="codeValue" size="large"></a-input>
<!--        <a-button type="primary" class="auth-btn" style="width: 84px">15s</a-button>-->
<!--      </a-input-group>-->
      <div v-if="isCodeValueDirty && isCodeValueRequired" class="error-message">Code field is required.</div>
    </div>
    <div v-if="!codeNotVerified" class="mt-1">
      <label>{{ $t('enter-password') }}</label>
      <a-input-password
          v-model:value="password"
          size="large"
          class="password-input"
          :style="{ borderColor: isFocusedFirst ? '#e84749' : '#434343', 'box-shadow': 'none' }"
          @focus="isFocusedFirst = true"
          @blur="isFocusedFirst = false"
      ></a-input-password>
      <div v-if="isPasswordDirty && isPasswordRequired" class="error-message">Password field is required.</div>
      <div v-if="isPasswordDirty && isPasswordValid && !isPasswordRequired"  class="error-message">Password should contain 1 capital letter, 1 number, 1 special symbol and be longer or equal 8 symbols</div>
    </div>
    <div v-if="!codeNotVerified" class="mt-1">
      <label>{{ $t('repeat-password') }}</label>
      <a-input-password
          v-model:value="repeatedPassword"
          size="large"
          class="password-input"
          :style="{ borderColor: isFocusedSecond ? '#e84749' : '#434343', 'box-shadow': 'none' }"
          @focus="isFocusedSecond = true"
          @blur="isFocusedSecond = false"
      ></a-input-password>
      <div v-if="isRepeatedPasswordDirty && isRepeatedPasswordRequired" class="error-message">Repeated password field is required.</div>
      <div v-else-if="isRepeatedPasswordDirty && isRepeatedPasswordSameAs" class="error-message">Passwords do not match.</div>
    </div>
    <div class="mt-2 btn-wrap">
      <a-button
          v-if="codeNotVerified"
          @click="verifyCode"
          class="auth-btn"
          size="large"
          type="primary"
      >
        {{ $t('register') }}
      </a-button>
      <a-button
          v-if="!codeNotVerified"
          @click="register"
          class="auth-btn"
          size="large"
          type="primary"
      >
        {{ $t('register') }}
      </a-button>
    </div>
    <div v-if="errorVerifyMessage" class="error-message">{{errorVerifyMessage}}</div>
    <div v-if="errorFinishMessage" class="error-message">{{errorFinishMessage}}</div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import ValidationMixin from '@/mixins/ValidationMixin.js';
import {notification} from "ant-design-vue";
import EventPass from "@/event-pass";

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      email: '',
      codeValue: '',
      password: '',
      repeatedPassword: '',
      codeNotVerified: true,
      isFocusedFirst: false,
      isFocusedSecond: false,
      errorVerifyMessage: '',
      errorFinishMessage: '',
    };
  },
  created() {
    if (this.$route.query.verif || this.$route.query.email) {
      this.codeValue = this.$route.query.verif
      const url = this.$route.query.email
      if (url) {
        this.email = decodeURIComponent(url).replace(/ /g, "+");
      }
    }
  },
  watch: {
    codeNotVerified(v) {
      if (!v) {
        this.errorVerifyMessage = ''
      }
    }
  },
  methods: {
    async verifyCode() {
      this.v$.email.$touch();
      this.v$.codeValue.$touch();
      if (this.v$.email.$errors.length || this.v$.codeValue.$errors.length){
        return;
      }
      try {
        await this.$store.dispatch('UserModule/registerValidateCode', {
          email: this.email,
          code_candidate: this.codeValue
        })
        this.codeNotVerified = false
      } catch(e) {
          this.errorVerifyMessage = e.response.data.errors[0].detail
      }
    },
    async register() {
      this.v$.$touch();
      if (this.v$.email.$errors.length || this.v$.password.$errors.length || this.v$.repeatedPassword.$errors.length) {
        return;
      }
      try {
        await this.$store.dispatch('UserModule/registerUser', {
          email: this.email,
          password: this.password,
          code_candidate: this.codeValue
        })

        const { data } = await this.$store.dispatch('UserModule/loginUser', {
          email: this.email,
          password: this.password,

        })
        localStorage.setItem('accessToken', data.access)
        localStorage.setItem('refreshToken', data.refresh)

        EventPass.$emit('userLogged')
        await this.$router.push('/')
      } catch (e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/auth-components.scss';
</style>
