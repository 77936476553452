import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import Auth from '@/views/Auth.vue';
import Profile from "@/views/Profile";
import Subscribers from "@/views/Subscribers";
import SubscriberDetail from "@/views/SubscriberDetail";
import Suppliers from "@/views/Suppliers";
import SupplierDetail from "@/views/SupplierDetail";
import EmergencyService from "@/views/EmergencyService";
import UserDataBase from "@/views/UserDataBase";
import ResponseUnits from "@/views/ResponseUnits";
import ArrivalRecords from "@/views/ArrivalRecords.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: { requiresAuth: false }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/subscribers/',
    name: 'Subscribers',
    component: Subscribers,
    meta: {
      requiresAuth: true,
      permission: 'view_subscriber',
      allowedRoles: ['Admin', 'Superadmin', 'PCO']
    },
    children: [
      {
        path: ':slug',
        name: 'SubscriberDetail',
        component: SubscriberDetail,
        meta: {
          requiresAuth: true,
          permission: 'view_subscriber',
          allowedRoles: ['Admin', 'Superadmin', 'PCO']
        },
      }
    ]
  },
  {
    path: '/suppliers/',
    name: 'Suppliers',
    component: Suppliers,
    meta: {
      requiresAuth: true,
      permission: 'view_supplier',
      allowedRoles: ['Admin', 'Superadmin', 'SBS']
    },
    children: [
      {
        path: ':slug',
        name: 'SupplierDetail',
        component: SupplierDetail,
        meta: {
          requiresAuth: true,
          permission: 'view_supplier',
          allowedRoles: ['Admin', 'Superadmin', 'SBS']
        },
      }
    ]
  },
  {
    path: '/emergency-service',
    name: 'Emergency Service',
    component: EmergencyService,
    meta: {
      requiresAuth: true,
      permission: 'view_user',
      allowedRoles: ['Admin', 'Superadmin', 'PCO']
    }
  },
  {
    path: '/user-database',
    name: 'User Database',
    component: UserDataBase,
    meta: {
      requiresAuth: true,
      permission: 'view_user',
      allowedRoles: ['Admin', 'Superadmin']
    }
  },
  {
    path: '/response-units',
    name: 'Response Units',
    component: ResponseUnits,
    meta: {
      requiresAuth: true,
      permission: 'view_responseunit',
      allowedRoles: ['Admin', 'Superadmin', 'PCO']
    }
  },
  {
    path: '/arrival-records',
    name: 'Arrival records',
    component: ArrivalRecords,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('accessToken')

  const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
  const permission = to.meta.permission;
  const allowedRoles = to.meta.allowedRoles;

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated,
    // redirect to the authentication page
    next('/auth');
  }
  else if (permission) {
    const userRole = JSON.parse(localStorage.getItem('role'))
    const userPermissions = JSON.parse(localStorage.getItem('permissions'));
    const hasAccess = userRole === 'Superadmin' || userPermissions?.some(p => p.codename === permission);

    let hasRole = true;
    if (allowedRoles) {
      hasRole = allowedRoles.includes(userRole);
    }

    if (hasAccess && hasRole) {
      next(); // Allow the user to proceed to the page
    } else {
      next('/'); // Redirect the user to another page, e.g., home
    }
  }

  // else if (!requiresAuth && isAuthenticated) {
  //   // If the route does not require authentication and the user is already authenticated,
  //   // redirect to the home page
  //   next('/');
  // }
  else {
    // Otherwise, proceed to the next route
    next();
  }
});

export default router;
