import axios from '@/axios';

export default {
  namespaced: true,
  state: {
    // Module state goes here
  },
  mutations: {
    // Module mutations go here
  },
  actions: {
    async getSubsList(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get('/subscribers/list/', {
        params: queryParams
      })
    },
    async getSubDetail(ctx, payload) {
      return await axios.get(`/subscribers/detail/${payload}/`)
    },
    async getSubContacts(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/subscribers/contacts/list/${payload.uid}/`, {
        params: queryParams
      })
    },
    async getSeparatedContactsList(ctx, payload) {
      return await axios.get(`/subscribers/positions/contacts/list/${payload}/`)
    },
    async getSubUsers(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/subscribers/users/list/${payload.uid}/`, {
        params: queryParams
      })
    },
    async createSubscriber(ctx, payload) {
      return await axios.post('/subscribers/create/', payload)
    },
    async editSubscriber(ctx, payload) {
      return await axios.patch(`/subscribers/update/${payload.uid}/`, payload.companyData)
    },
    async deleteSubscriber(ctx, payload) {
      return await axios.delete(`/subscribers/delete/${payload}/`)
    },
    async createContact(ctx, payload) {
      return await axios.post('/subscribers/contact/create/', payload)
    },
    async updateContact(ctx, payload) {
      return await axios.patch(`/subscribers/contact/update/${payload.uid}/`, payload.data)
    },
    async deleteContact(ctx, payload) {
      return await axios.delete(`/subscribers/contact/delete/${payload}/`)
    },
    async getPositionsList() {
      return await axios.get('/subscribers/postions/list/')
    },
    async createNewUser(ctx, payload) {
      return await axios.post('/subscribers/user/create/', payload)
    },
    async assignUser(ctx, payload) {
      return await axios.patch(`/subscribers/users/assign/${payload.uid}/`, {
        users_uids: payload.users_uids
      })
    },
    async unAssignUser(ctx, payload) {
      return await axios.patch(`/subscribers/users/unassign/${payload.uid}/`, {
        users_uids: payload.users_uids
      })
    },
    async createDocument(ctx, payload) {
      return await axios.post('/subscribers/documents/create/', payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async deleteDocument(ctx, payload) {
      return await axios.delete(`/subscribers/documents/delete/${payload}/`)
    },
    async exportData(ctx, payload) {
      return await axios.post(`/subscribers/export/`, payload)
    },
    async exportDetail(ctx, payload) {
      return await axios.post(`/subscribers/export/detail`, payload)
    }
  },
  getters: {
    // Module getters go here
  }
};
