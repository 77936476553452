import axios from '@/axios';

export default {
  namespaced: true,
  state: {
    user: {}
  },
  mutations: {
    commitUserData(state,payload) {
      state.user = payload
    }
  },
  actions: {
    async registerValidateCode(ctx, payload) {
      return await axios.post('/user/email/validate/code/', payload)
    },
    async registerUser(ctx, payload) {
      return await axios.post('/user/registration/finish/', payload);
    },
    async loginUser(ctx, payload) {
      return await axios.post('/user/auth/email/', payload)
    },
    async resetRequest(ctx, payload) {
      return await axios.post('/user/password/reset/request/', payload)
    },
    async resetCheckCode(ctx, payload) {
      return await axios.post('/user/password/reset/check_code/', payload)
    },
    async resetSubmit(ctx, payload) {
      return await axios.post('/user/password/reset/submit/', payload)
    },
    async usersList() {
      return await axios.get('/user/users/list/')
    },
    async deleteUser(ctx, payload) {
      return axios.delete(`/user/delete/${payload}/`)
    },
    async grantPermission(ctx, payload) {
      return await axios.patch(`/user/grant/permission/${payload}/`)
    },
    async getListOfPermissions() {
      return await axios.get(`/user/permissions/`)
    },
    async revokePermission(ctx, payload) {
      return await axios.patch(`/user/revoke/permission/${payload}/`)
    },
    async getDataBaseList(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/user/users/database/list/`, {
        params: queryParams
      })
    },
    async exportData(ctx, payload) {
      return await axios.post('/user/export/', payload)
    },
    async getUserDetail() {
      return await axios.get('/user/detail/')
    },
    async updateUserData(ctx, payload) {
      return await axios.patch('/user/update/', payload)
    },
    async updateAvatar(ctx, payload) {
      return await axios.patch('/user/avatar/', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }

  },
  getters: {
    user: state => state.user
  }
};
