<template>
  <a-modal v-model:visible="localShowModal" :title="$t('modal-titles.delete-f-system')" @cancel="handleCancel">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('delete')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <a-row class="data-container" style="margin-top: 0">
      {{$t('modal-titles.sure-delete-f-s')}}
    </a-row>
  </a-modal>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      localShowModal: this.showModal,
    }
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
  },
  methods: {
    handleOk() {
      this.$emit('delete')
    },
    handleCancel() {
      this.$emit('close')
    },
  }
}
</script>