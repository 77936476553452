<template>
  <div>
    <h1 class="auth-title">
      {{ $t('recovery-password') }}
    </h1>
    <div v-if="codeNotSent">
      <div  class="mt-2">
        <label>{{ $t('email') }}</label>
        <a-input v-model:value="email" size="large"></a-input>
      </div>
      <div v-if="isEmailDirty">
        <div v-if="isEmailRequired" class="error-message">Email field is required.</div>
        <div v-else-if="isEmailValid" class="error-message">Email field must be a valid email.</div>
      </div>
      <div v-if="emailError" class="error-message">{{emailError}}</div>
      <div class="mt-2 btn-wrap">
        <a-button @click="sendCode" type="primary" class="auth-btn" size="large">
          {{$t('send-code')}}
        </a-button>
      </div>
    </div>
    <div v-if="codeSent">
      <div>
        <label>{{ $t('email') }}</label>
        <a-input-group class="mt-2 input-group">
          <a-input v-model:value="email" size="large" />
          <a-button
              type="primary"
              class="auth-btn"
              style="width: 84px"
              @click="changeEmail"
          >{{$t('other-mail')}}</a-button>
        </a-input-group>
        <div v-if="isEmailDirty">
          <div v-if="isEmailRequired" class="error-message">Email field is required.</div>
          <div v-else-if="isEmailValid" class="error-message">Email field must be a valid email.</div>
        </div>
      </div>
      <div class="mt-2">
        <label>{{ $t('code') }}</label>
          <a-input v-model:value="codeValue" size="large" />
          <div v-if="isCodeValueDirty && isCodeValueRequired" class="error-message">Code field is required.</div>
          <div v-if="codeError" class="error-message">{{ codeError }}</div>
      </div>
      <div class="mt-2 btn-wrap">
        <a-button @click="confirmCode" type="primary" class="auth-btn" size="large">
          {{$t('reset-password')}}
        </a-button>
      </div>

    </div>
    <div v-if="setupPassword">
      <div  class="mt-2">
        <label>{{ $t('email') }}</label>
        <a-input v-model:value="email" size="large"></a-input>
        <div v-if="isEmailDirty">
          <div v-if="isEmailRequired" class="error-message">Email field is required.</div>
          <div v-else-if="isEmailValid" class="error-message">Email field must be a valid email.</div>
        </div>
      </div>
      <div class="mt-1">
        <label>{{ $t('enter-password') }}</label>
        <a-input-password
            v-model:value="password"
            size="large"
            class="password-input"
            :style="{ borderColor: isFocusedFirst ? '#e84749' : '#434343', 'box-shadow': 'none' }"
            @focus="isFocusedFirst = true"
            @blur="isFocusedFirst = false"
        ></a-input-password>
        <div v-if="isPasswordDirty && isPasswordRequired" class="error-message">Password field is required.</div>
        <div v-if="isPasswordDirty && isPasswordValid && !isPasswordRequired"  class="error-message">Password should contain 1 capital letter, 1 number, 1 special symbol and be longer or equal 8 symbols</div>
      </div>
      <div class="mt-1">
        <label>{{ $t('repeat-password') }}</label>
        <a-input-password
            v-model:value="repeatedPassword"
            size="large"
            class="password-input"
            :style="{ borderColor: isFocusedSecond ? '#e84749' : '#434343', 'box-shadow': 'none' }"
            @focus="isFocusedSecond = true"
            @blur="isFocusedSecond = false"
        ></a-input-password>
        <div v-if="isRepeatedPasswordDirty && isRepeatedPasswordRequired" class="error-message">Repeated password field is required.</div>
        <div v-else-if="isRepeatedPasswordDirty && isRepeatedPasswordSameAs" class="error-message">Passwords do not match.</div>
      </div>
      <div class="mt-2 btn-wrap">
        <a-button @click="resetPassword" type="primary" class="auth-btn" size="large">
          {{$t('reset-password')}}
        </a-button>
        <div v-if="passwordError" class="error-message">{{passwordError}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import ValidationMixin from '@/mixins/ValidationMixin.js';
import {notification} from "ant-design-vue";
import EventPass from "@/event-pass";

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      email: '',
      codeValue: '',
      password: '',
      repeatedPassword: '',
      codeSent: false,
      codeNotSent: true,
      setupPassword: false,
      isFocusedFirst: false,
      isFocusedSecond: false,
      emailError: '',
      codeError: '',
      passwordError: '',
    }
  },
  created() {
    if (this.$route.query.reset && this.$route.query.email) {
      this.codeSent = true
      this.codeValue = this.$route.query.reset
      const url = this.$route.query.email

      if (url) {
        this.email = decodeURIComponent(url).replace(/ /g, "+");
      }
    }
  },
  watch: {
    codeSent(v) {
      if (v) {
        this.codeNotSent = false
        this.setupPassword = false
      }
    },
    codeNotSent(v) {
      if (v) {
        this.codeSent = false
        this.setupPassword = false
      }
    },
    setupPassword(v) {
      if (v) {
        this.codeSent = false
        this.codeNotSent = false
      }
    },
    email(v) {
      if(v) {
        this.emailError = ''
      }
    },
    codeValue(v) {
      if(v) {
        this.codeError = ''
      }
    },
    password(v) {
      if(v) {
        this.passwordError = ''
      }
    }
  },
  methods: {
    async sendCode() {
      try {
        this.v$.email.$touch();
        if (this.v$.email.$errors.length){
          return;
        }
        await this.$store.dispatch('UserModule/resetRequest', {
          email: this.email
        })
        this.codeSent = true

      } catch (e) {
        this.emailError = e.response.data.errors[0].detail
      }

    },
    async confirmCode() {
      this.v$.email.$touch();
      this.v$.codeValue.$touch();
      if (this.v$.email.$errors.length || this.v$.codeValue.$errors.length){
        return;
      }
      try {
        await this.$store.dispatch('UserModule/resetCheckCode', {
          email_candidate: this.email,
          code_candidate: this.codeValue
        })
      } catch(e) {
        this.codeError = e.response.data.errors[0].detail
        return
      }
      this.setupPassword = true
    },
    async resetPassword() {
      this.v$.$touch();
      if (this.v$.email.$errors.length
          || this.v$.password.$errors.length
          || this.v$.repeatedPassword.$errors.length
          || this.v$.codeValue.$errors.length) {
        return;
      }
      try {
        await this.$store.dispatch('UserModule/resetSubmit', {
          code_candidate: this.codeValue,
          password_candidate: this.password
        })
        const { data } = await this.$store.dispatch('UserModule/loginUser', {
          email: this.email,
          password: this.password
        })
        await localStorage.setItem('accessToken', data.access)
        await localStorage.setItem('refreshToken', data.refresh)

        EventPass.$emit('userLogged')
        await notification.success({
          message: this.$t("notifications.success"),
          description: this.$t("notifications.password-changed"),
        });
        await this.$router.push('/')
      } catch(e) {
        notification.error({
          message: this.$t('notifications.error'),
          description: e.response.data.errors[0].detail,
        })
      }
    },
    changeEmail() {
      this.email = ''
      this.codeNotSent = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/components/auth-components.scss';
</style>