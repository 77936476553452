<template>
  <a-modal v-model:visible="localShowModal" :title="title" @cancel="handleCancel" width="400px">
    <template #footer>
      <a-button key="submit" type="primary" @click="handleOk">{{$t('add')}}</a-button>
      <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
    </template>
    <a-select
        v-model:value="user"
        style="width: 100%"
        class="select-custom-style"
        :placeholder="$t('select')"
        :options="options"
        @change="handleChange"
    ></a-select>
  </a-modal>
</template>

<script>
import {notification} from "ant-design-vue";

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    units: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    },
    companyUsers: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      localShowModal: this.showModal,
      user: {},
      options: []
    }
  },
  computed: {
    isUserSuperadmin() {
      return this.$store.getters['UserModule/user'].role === 'Superadmin'
    },
  },
  watch: {
    showModal(newVal) {
      this.localShowModal = newVal;
    },
  },
  async mounted() {
    try {
      let response
      if(this.units) {
         response = await this.$store.dispatch('Suppliers/getListOfUnitsForModal')
      } else {
        response = await this.$store.dispatch('UserModule/usersList')
      }

      let responseUsers = response?.data?.results || [];

      if (!this.isUserSuperadmin) {
        responseUsers = responseUsers.filter(u => (!['admin','superadmin'].includes(u.role?.toLowerCase() || '')));
      }
      
      if (this.companyUsers?.length) {
        const companyUsersIds = this.companyUsers.map(u => u.uid)
        responseUsers = responseUsers.filter(u => !companyUsersIds.includes(u.uid))
      }

      if (this.units) {
        this.options = responseUsers.map(u => {
          return {
            label: u.unit_id,
            value: u.uid
          }
        })
      } else {
        this.options = responseUsers.map(u => {
          return {
            label: u.username || u.email,
            value: u.uid
          }
        })
      }


      this.options.unshift({
        label: `+ ${this.$t(`create-${this.units ? 'unit' : 'user'}`)}`,
        value: 'create'
      })
    } catch(e) {
      notification.error({
        message: this.$t('notifications.error'),
        description: e.response.data.errors[0].detail,
      })
    }
  },
  methods: {
    handleOk() {
      this.$emit('add', this.user)
    },
    handleCancel() {
      this.$emit('close')
      this.user = {}
    },
    handleChange(value) {
      if(value === 'create') {
        this.$emit('add', this.user)
        this.user = {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/table-styles.scss";
:deep(.ant-select-selector) {
  height: 40.5px !important;
  border-color: #434343 !important;
  &:hover {
    border-color: var(--red-7) !important;
  }
  &:focus {
    border-color: var(--red-7) !important;
  }

}
</style>