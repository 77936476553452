<template>
  <div>
    <h1 class="auth-title mt-3">
      {{ $t("login") }}
    </h1>
    <div class="mt-2">
      <label>{{ $t("l-place") }}</label>
      <a-input v-model:value="email" size="large"></a-input>
      <div v-if="isEmailDirty">
        <div v-if="isEmailRequired" class="error-message">
          {{ $t("field-required") }}
        </div>
<!--        <div v-else-if="isEmailValid" class="error-message">-->
<!--          {{ $t("valid-email") }}-->
<!--        </div>-->
      </div>
    </div>
    <div class="mt-1">
      <label>{{ $t("password") }}</label>
      <a-input-password
        v-model:value="password"
        size="large"
        class="password-input"
        :style="{
          borderColor: isFocused ? '#e84749' : '#434343',
          'box-shadow': 'none',
        }"
        @focus="isFocused = true"
        @blur="isFocused = false"
      ></a-input-password>
      <div v-if="isPasswordDirty && isPasswordRequired" class="error-message">
        {{ $t("field-required") }}
      </div>
    </div>
    <div class="mt-2 btn-wrap">
      <a-button @click="authorize" type="primary" class="auth-btn" size="large">
        {{ $t("authorize") }}
      </a-button>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
    <div class="mt-2 btn-wrap">
      <a-button @click="$emit('resetPassword')" class="link-btn" type="link">{{
        $t("forgot-pass")
      }}</a-button>
    </div>
    <!--    <div class="mt-6 btn-wrap">-->
    <!--      <a-button @click="$emit('registration')" class="text-btn" type="text">{{ $t('register') }}</a-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import ValidationMixin from "@/mixins/ValidationMixin.js";
import { notification } from "ant-design-vue";
import EventPass from "@/event-pass";

export default {
  mixins: [ValidationMixin],
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      email: "",
      password: "",
      isFocused: false,
      errorMessage: "",
    };
  },
  watch: {
    email(v) {
      if (v) {
        this.errorMessage = "";
      }
    },
    password(v) {
      if (v) {
        this.errorMessage = "";
      }
    },
  },
  methods: {
    async authorize() {
      this.v$.$touch();
      if (
        this.isEmailRequired ||
        this.isPasswordRequired
      ) {
        return;
      }
      try {
        const { data } = await this.$store.dispatch("UserModule/loginUser", {
          email: this.email,
          password: this.password,
        });
        await localStorage.setItem("accessToken", data?.access);
        await localStorage.setItem("refreshToken", data?.refresh);
        // await localStorage.setItem("role", JSON.stringify(data?.role));
        // await localStorage.setItem("supplier", JSON.stringify(data?.supplier));
        // await localStorage.setItem("subscriber", JSON.stringify(data?.subscriber));
        // await localStorage.setItem("last-object", JSON.stringify(data?.last_object))
        // if(data) {
        //   const {data: user} = await this.$store.dispatch('UserModule/getUserDetail')
        //   this.$store.commit('UserModule/commitUserData', user)
        //   await localStorage.setItem('permissions', JSON.stringify(user.user_permissions))
        // }
        EventPass.$emit('userLogged')
        await this.$router.push('/')
      } catch (e) {
        console.log(e);
        notification.error({
          message: this.$t("notifications.error"),
          description: this.$t("login-error"),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/components/auth-components.scss";
.btn-wrap {
  text-align: center;
}
</style>
