import axios from '@/axios';

export default {
  namespaced: true,
  state: {
    // Module state goes here
  },
  mutations: {
    // Module mutations go here
  },
  actions: {
    async getUsersList(ctx, payload) {
      const queryParams = {};
      queryParams.page_size = payload.page_size;
      queryParams.page = payload.page
      return await axios.get(`/emergency/user/list/`, {
        params: queryParams
      })
    },
    async createUser(ctx, payload) {
      return await axios.post('/emergency/user/create/', payload)
    },
    async updateUser(ctx, payload) {
      return await axios.patch(`/emergency/user/profile/update/${payload.uid}/`, payload)
    },
    async exportData(ctx, payload) {
      return await axios.post('/emergency/export/', payload)
    }

  },
  getters: {
    // Module getters go here
  }
};
