import { createStore } from 'vuex'
import UserModule from '@/store/user'
import Subscribers from "@/store/subscribers";
import Suppliers from "@/store/suppliers";
import Emergency from "@/store/emergency";
import Map from "@/store/map";

export default createStore({
  state: {
    mobileView: false
  },
  mutations: {
    changeStatusOfView(state, payload) {
      state.mobileView = payload
    }
  },
  actions: {
  },
  getters: {
    isMobileView(state) {
      return state.mobileView
    }
  },
  modules: {
    UserModule,
    Subscribers,
    Suppliers,
    Emergency,
    Map
  }
})
