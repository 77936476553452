<template>
  <div class="auth-wrap">
    <div class="content">
      <Login
          v-if="login"
          @resetPassword="resetPassword = true"
      ></Login>
      <Registration v-if="registration" />
      <NewPassword v-if="resetPassword" />
    </div>

  </div>

</template>

<script>
import Login from "@/components/UserFlow/Login";
import Registration from "@/components/UserFlow/Registration";
import NewPassword from "@/components/UserFlow/ResetPassword";

export default {
  components: {
    Login,
    Registration,
    NewPassword
  },
  data() {
    return {
      login: true,
      registration: false,
      resetPassword: false,
    }
  },
  created() {
    if (this.$route.query.verif && this.$route.query.email) {
      this.registration = true
      return
    }
    if (this.$route.query.reset && this.$route.query.email) {
      this.resetPassword = true
      return
    }
  },
  computed: {

  },
  watch: {
    login(v) {
      if (v) {
        this.registration = false
        this.resetPassword = false
      }
    },
    registration(v) {
      if (v) {
        this.login = false
        this.resetPassword = false
      }
    },
    resetPassword(v) {
      if (v) {
        this.login = false
        this.registration = false
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.auth-wrap {
  text-align: left;
  color: #FFFFFF;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    width: 350px;
    :deep(.auth-title) {
      color: #FFFFFF;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
    }
    @media (max-width: 400px) {
      width: 300px;
    }
  }

}
</style>