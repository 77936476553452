import parsePhoneNumberFromString from 'libphonenumber-js'

const parsePhoneNumber = (value) => {
    let tel = value || '';
    if (tel.startsWith('420')) tel = '+' + tel;
    const phoneNumber = parsePhoneNumberFromString(tel, 'CZ');
    return phoneNumber;
}

const storageAvailable = (type) => {
    try {
      var storage = window[type],
        x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch(e) {
      return false;
    }
}

const setValueToLocalStorage = (key, value) => {
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem(key, value)
  }
}

const getValueFromLocalStorage = (key) => {

  let result = null

  if (storageAvailable('localStorage')) {
    result = window.localStorage.getItem(key) || null;
  }

  return result;

}

export {
    parsePhoneNumber,
    storageAvailable,
    setValueToLocalStorage,
    getValueFromLocalStorage
}