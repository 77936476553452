import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Antd from 'ant-design-vue';
import '@/assets/scss/variables.scss';
import 'ant-design-vue/dist/antd.dark.css';
import axiosInstance from '@/axios';
import moment from "moment";

import InlineSvg from 'vue-inline-svg';
import { createI18n } from 'vue-i18n';

import CsLocale from '@/locales/cs.json';
import EnLocale from '@/locales/en.json';
import store from '@/store';

const i18n = createI18n({
  locale: 'cs',
  legacy: false,
  messages: {
    cs: CsLocale,
    en: EnLocale
  }
});

const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.$moment = moment;
app.use(store)
  .use(router)
  .use(Antd)
  .use(i18n)
  .component('inline-svg', InlineSvg)
  .mount('#app');
router.app = app
